html {
  scroll-behavior: smooth;
}
.coreal-loader {
  opacity: 0;
}
.coreal-loader.active {
  opacity: 100;
}
.coreal-visible {
  transition: all 0.5s ease-in-out;
}
.coreal-visible {
  opacity: 0;
}
.coreal-visible.active {
  opacity: 100;
}
.overflow-style-none::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.overflow-style-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.coreal-menu-item-has-children {
  transition: all 0.5s ease-out;
}
.coreal-menu-item-has-children ul {
  transition: all 0.5s ease-in;
}
.sidebar-menu {
  max-height: 0px;
}
.coreal-map-3-area {
  height: 400px;
}
.coreal-checkbox-box.checkbox_style6 li input[type="checkbox"] {
  appearance: none;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 20px;
  border: 2px solid #eee;
}
.coreal-checkbox-box.checkbox_style6 li input[type="checkbox"]:before {
  content: "";
  width: 100%;
  height: 100%;
  transform: scale(1);
  background: transperent;
  display: block;
  cursor: pointer;
  border-radius: 100%;
}
.coreal-checkbox-box li input[type="checkbox"],
.coreal-checkbox-box li input[type="checkbox"]:checked {
  transition: background-color 0.2s linear;
}
.coreal-checkbox-box li input[type="checkbox"]:checked:before {
  transform: scale(0.6);
  background: #3257a8;
}
.coreal-checkbox-box li input[type="checkbox"]:checked {
  border-color: #3257a8;
}
.coreal-er-contact-us-box {
  position: relative;
}
.coreal-select-item.item-news .my-select-box button {
  border: 2px solid #d8d8d8;
}
.coreal-er-contact-us-box form button.my-select-box-btn {
  position: static;
  transform: translateX(0);
}
