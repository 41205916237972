/*-----------------------------------------------------------------------------------

    Template Name: Sample - Corporate Business Bootstrap4 HTML5 Template
    Template URI: site.com
    Description: Sample - Corporate Business Bootstrap4 HTML5 Template
    Author: MD THAHERIL ISLAM
    Author URI: site.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/
/*===========================
    1. COMMON css 
===========================*/
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
html,
body {
  height: 100%;
}

*,
:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 28px;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

a {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
}
a:hover {
  color: #3257a8;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #111111;
  margin: 0px 0 15px;
  line-height: normal;
}

p {
  margin: 0 0 15px;
}

.banner__bg {
  position: absolute;
  left: 0;
  top: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.slick-slide {
  outline: 0;
}

.page-link:focus {
  box-shadow: none;
}

@media only screen and (min-width: 1320px) {
  .custom-container {
    max-width: 1320px !important;
  }
}

.bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
}

.accordion-button {
  border: 0;
}

.accordion-collapse {
  border: 0;
}

.accordion-button::after {
  display: none;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none;
}

.accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

/*===== All Button Style =====*/
.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #3257a8;
  padding: 0 40px;
  font-size: 14px;
  line-height: 60px;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #3257a8;
}
.main-btn i {
  padding-right: 6px;
}
.main-btn:hover {
  background-color: #fff;
  color: #3257a8;
  border-color: #3257a8;
}
.main-btn.main-btn-2 {
  background-color: transparent;
  color: #fff;
  border-color: #1e1e1e;
}
.main-btn.main-btn-2:hover {
  background-color: #3257a8;
  border-color: #3257a8;
  color: #fff;
}

/*----------------------------------------
   offcanvas menu
----------------------------------------*/
.coreal-off_canvars_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  cursor: crosshair;
  background: #232323;
  top: 0;
}
.coreal-off_canvars_overlay.coreal-active {
  opacity: 0.5;
  visibility: visible;
}

.coreal-offcanvas_menu.coreal-offcanvas_menu_left
  .coreal-offcanvas_menu_wrapper {
  left: 0;
  right: auto;
  margin-right: 0;
  margin-left: -400px;
}
.coreal-offcanvas_menu.coreal-offcanvas_menu_left
  .coreal-offcanvas_menu_wrapper.coreal-active {
  margin-left: 0;
}

.coreal-offcanvas_menu_wrapper {
  width: 310px;
  position: fixed;
  background: #fff;
  z-index: 9999;
  top: 0;
  height: 100vh;
  transition: 0.5s;
  right: 0;
  margin-right: -310px;
  padding: 50px 15px 30px;
  overflow-y: auto;
}
.coreal-offcanvas_menu_wrapper.coreal-active {
  margin-right: 0;
}
.coreal-offcanvas_menu_wrapper .coreal-header-btn {
  margin-bottom: 30px;
}
.coreal-offcanvas_menu_wrapper .coreal-header-btn a {
  color: #111111;
}
.coreal-offcanvas_menu_wrapper .coreal-header-btn a:hover {
  color: #111111;
}
.coreal-offcanvas_menu_wrapper .coreal-header-social {
  padding-bottom: 30px;
}
.coreal-offcanvas_menu_wrapper .coreal-header-social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-offcanvas_menu_wrapper .coreal-header-social ul li {
  display: inline-block;
}
.coreal-offcanvas_menu_wrapper .coreal-header-social ul li a {
  font-size: 14px;
  margin: 0 5px;
  color: #777777;
}

.coreal-offcanvas_main_menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-offcanvas_main_menu li {
  position: relative;
}
.coreal-offcanvas_main_menu li:last-child {
  margin: 0;
}
.coreal-offcanvas_main_menu li span.menu-expand {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.coreal-offcanvas_main_menu li a {
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ededed;
  color: #777777;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.coreal-offcanvas_main_menu li a:hover {
  color: #3257a8;
}
.coreal-offcanvas_main_menu li ul {
  list-style-type: none;
}
.coreal-offcanvas_main_menu li ul.coreal-er-news-sub-menu {
  padding-left: 20px;
  list-style-type: none;
}

.coreal-offcanvas_footer {
  margin-top: 30px;
  padding-bottom: 50px;
  text-align: left;
}
.coreal-offcanvas_footer p {
  font-size: 14px;
  padding-top: 30px;
  padding-bottom: 30px;
  line-height: 24px;
}
.coreal-offcanvas_footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-offcanvas_footer ul li {
  font-size: 14px;
  line-height: 30px;
  padding-bottom: 15px;
  position: relative;
  padding-left: 24px;
}
.coreal-offcanvas_footer ul li i {
  color: #3257a8;
  position: absolute;
  left: 0;
  top: 7px;
}

.coreal-canvas_close {
  position: absolute;
  top: 10px;
  right: 13px;
}
.coreal-canvas_close a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  width: 32px;
  height: 32px;
  display: block;
  text-align: center;
  line-height: 32px;
  border: 1px solid #ededed;
  border-radius: 50%;
  color: #111111;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.coreal-canvas_close a:hover {
  background: #3257a8;
  border-color: #3257a8;
  color: #fff;
}

.coreal-canvas_open {
  cursor: pointer;
}
.coreal-canvas_open a {
  text-align: center;
}

/*----------------------------------------
   Search box
----------------------------------------*/
.coreal-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  transform-style: preserve-3d;
  visibility: hidden;
  opacity: 0;
  transition: all 0.75s cubic-bezier(0.7, 0, 0.3, 1);
  transition-delay: 0;
  overflow-y: auto;
}

.coreal-search:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transition: transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
  opacity: 0.3;
  transform: scale3d(1, 0, 1);
  transform-origin: 50% 100%;
}

.coreal-search__content {
  background-color: white;
  width: 100%;
  padding: 50px 20px;
  position: relative;
}

.coreal-search__content h3 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 26px;
  color: #103178;
  font-weight: 600;
}

.coreal-search .coreal-search__close {
  position: absolute;
  right: 20px;
  top: 50px;
  color: #103178;
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 10;
}

.corealcoreal-search .coreal-search__close i {
  font-weight: bold;
}

.coreal-search.active {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.coreal-search.active:before {
  transform: scale3d(1, 1, 1);
  transform-origin: 50% 0%;
}

.coreal-search .coreal-search__result {
  padding-top: 50px;
  display: none;
}

.coreal-search .coreal-search__result.active {
  display: block;
}

.coreal-search .coreal-search__item {
  margin-bottom: 30px;
}

.coreal-search-table {
  background-color: #f0f2f5;
  border-radius: 30px;
  overflow: hidden;
}

.coreal-search-table .form-control {
  border-color: #f0f2f5;
  color: #5b6c8f;
  height: 46px;
  font-size: 18px;
  border-radius: 30px 0 0 30px;
}

.coreal-search-table .form-control:focus {
  box-shadow: none;
  background-color: #f0f2f5;
  color: #5b6c8f;
}

.coreal-search-table .form-control::-moz-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.coreal-search-table .form-control:-ms-input-placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.coreal-search-table .form-control::placeholder {
  color: #5b6c8f;
  font-size: 16px;
}

.coreal-search-table .input-group-append {
  width: 60px;
  align-items: center;
  justify-content: center;
  color: #103178;
  text-align: center;
  line-height: 44px;
}

.coreal-search-table .input-group-append button {
  border: 0;
  background: transparent;
}

.coreal-search-table .input-group-append button i {
  color: #103178;
  font-weight: 900;
  font-size: 18px;
}

.section-title span {
  font-size: 14px;
  font-weight: 700;
  color: #3257a8;
}
.section-title .title {
  font-size: 48px;
  margin-bottom: 25px;
  padding-top: 6px;
  line-height: 58px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title .title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 767px) {
  .section-title .title {
    font-size: 28px;
    line-height: 36px;
  }
}

.coreal-get-in-touch-area {
  padding-top: 90px;
  padding-bottom: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-get-in-touch-area .section-title .title {
    font-size: 36px;
    line-height: 46px;
  }
}

.coreal-get-in-touch-item {
  border: 2px solid #f0f0f0;
  border-radius: 15px;
  padding-top: 65px;
  padding-bottom: 37px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-get-in-touch-item {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-get-in-touch-item {
    margin-bottom: 30px;
  }
}
.coreal-get-in-touch-item span {
  display: block;
  font-size: 14px;
  margin-top: 20px;
}
.coreal-get-in-touch-item .title {
  font-size: 24px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-get-in-touch-item .title {
    font-size: 20px;
  }
}
.coreal-get-in-touch-item:hover {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}

.coreal-map-3-area {
  position: relative;
  height: 600px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-map-3-area {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .coreal-map-3-area {
    height: 300px;
  }
}
.coreal-map-3-area #coreal-map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.coreal-map-3-area #coreal-map a.mapboxgl-ctrl-logo {
  background-image: none;
}
.coreal-map-3-area #coreal-map .mapboxgl-ctrl-attrib.mapboxgl-compact {
  display: none;
}
.coreal-map-3-area #coreal-map .marker {
  background-image: url("../images/marker-map.png");
  background-size: 100% 100%;
  width: 60px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
}
.coreal-map-3-area #coreal-map .mapboxgl-popup {
  max-width: 200px;
}
.coreal-map-3-area #coreal-map .mapboxgl-popup-content {
  text-align: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-map-listing-list.pl-85 {
    padding-left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-map-listing-list.pl-85 {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-map-listing-list.pl-85 {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .coreal-map-listing-list.pl-85 {
    padding-left: 0;
  }
}

/*--------------------------------------------------------------------------------------
PRELOADER
--------------------------------------------------------------------------------------*/
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #3257a8;
  z-index: 9999999;
  text-align: center;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.line {
  width: 5px;
  height: 40px;
  background: #fff;
  margin: 0 3px;
  border-radius: 10px;
  animation: loading 0.8s infinite;
}

.line:nth-child(2) {
  animation-delay: 0.1s;
}

.line:nth-child(3) {
  animation-delay: 0.2s;
}

.line:nth-child(4) {
  animation-delay: 0.3s;
}

.line:nth-child(5) {
  animation-delay: 0.4s;
}

.line:nth-child(6) {
  animation-delay: 0.5s;
}

.line:nth-child(7) {
  animation-delay: 0.6s;
}

.line:nth-child(8) {
  animation-delay: 0.7s;
}

@keyframes loading {
  0% {
    height: 0;
  }
  50% {
    height: 40px;
  }
  100% {
    height: 0;
  }
}
/*===========================
    2.HEADER css 
===========================*/
.coreal-header-area {
  box-shadow: 0 -22px 32px 0px rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 999;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-area {
    padding: 20px 0;
  }
}
@media (max-width: 767px) {
  .coreal-header-area {
    padding: 15px 0;
  }
}

.coreal-header-items {
  position: relative;
  padding: 0;
}
.coreal-header-items .coreal-header-brand {
  align-items: center;
}

.coreal-header-btns {
  margin-left: 60px;
}
@media (max-width: 767px) {
  .coreal-header-btns {
    margin-left: 30px;
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-header-btns {
    margin-left: 30px;
    display: block;
  }
}
.coreal-header-btns ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-header-btns ul li {
  display: inline-block;
}
.coreal-header-btns ul li a {
  font-size: 14px;
  font-weight: 700;
  color: #777777;
  border: 2px solid #fff;
  padding: 0 26px;
  line-height: 46px;
  display: inline-block;
  border-radius: 15px;
}
.coreal-header-btns ul li:first-child a {
  color: #3257a8;
  border: 2px solid #ededed;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-main-menu {
    display: none;
  }
}
@media (max-width: 767px) {
  .coreal-header-main-menu {
    display: none;
  }
}
.coreal-header-main-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-header-main-menu ul > li {
  display: inline-block;
  margin-left: 53px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-header-main-menu ul > li {
    margin-left: 25px;
  }
}
.coreal-header-main-menu ul > li > a {
  font-size: 14px;
  font-weight: 700;
  color: #777777;
  line-height: 100px;
}
.coreal-header-main-menu ul > li > a i {
  color: #c1c1c1;
  padding-left: 6px;
}
.coreal-header-main-menu ul > li .sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: auto;
  min-width: 200px;
  max-width: 200px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
  z-index: 99;
  -webkit-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  -moz-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  list-style-type: none;
  margin: 0;
  padding: 15px 0;
  border-radius: 5px;
  text-align: left;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-header-main-menu ul > li .sub-menu {
    min-width: 200px;
    max-width: 200px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-header-main-menu ul > li .sub-menu {
    min-width: 200px;
    max-width: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-main-menu ul > li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .coreal-header-main-menu ul > li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}
.coreal-header-main-menu ul > li .sub-menu > li {
  position: relative;
  margin-left: 0;
  display: block;
}
.coreal-header-main-menu ul > li .sub-menu > li .sub-menu {
  margin-left: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-main-menu ul > li .sub-menu > li .sub-menu {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .coreal-header-main-menu ul > li .sub-menu > li .sub-menu {
    margin-left: 0;
  }
}
.coreal-header-main-menu ul > li .sub-menu > li .sub-nav-toggler {
  color: #404040;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.coreal-header-main-menu ul > li .sub-menu > li a {
  display: block;
  padding: 0px 30px;
  position: relative;
  color: #777777;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 4px;
  margin: 0 0;
  line-height: 2.5;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-header-main-menu ul > li .sub-menu > li a {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-header-main-menu ul > li .sub-menu > li a {
    padding: 0 20px;
  }
}
.coreal-header-main-menu ul > li .sub-menu > li a i {
  float: right;
  font-size: 16px;
  margin-top: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-main-menu ul > li .sub-menu > li a i {
    display: none;
  }
}
@media (max-width: 767px) {
  .coreal-header-main-menu ul > li .sub-menu > li a i {
    display: none;
  }
}
.coreal-header-main-menu ul > li .sub-menu > li a .sub-nav-toggler i {
  display: inline-block;
}
.coreal-header-main-menu ul > li .sub-menu > li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-main-menu ul > li .sub-menu > li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
@media (max-width: 767px) {
  .coreal-header-main-menu ul > li .sub-menu > li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
.coreal-header-main-menu ul > li .sub-menu > li .sub-menu li {
  position: relative;
}
.coreal-header-main-menu ul > li .sub-menu > li .sub-menu li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.coreal-header-main-menu ul > li .sub-menu > li .sub-menu li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}
.coreal-header-main-menu ul > li .sub-menu > li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}
.coreal-header-main-menu ul > li .sub-menu > li:hover .sub-nav-toggler {
  color: #3257a8;
}
.coreal-header-main-menu ul > li .sub-menu > li:hover > a {
  color: #3257a8;
}
.coreal-header-main-menu ul > li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.coreal-header-bar {
  position: absolute;
  left: 410px;
  bottom: -25px;
  background: #3257a8;
  padding: 10px 20px;
  border-radius: 14px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-bar {
    left: auto;
    right: 0;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  .coreal-header-bar {
    left: auto;
    right: 0;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}
.coreal-header-bar ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-header-bar ul li {
  display: inline-block;
}
.coreal-header-bar ul li a {
  color: #fff;
  padding-right: 20px;
  margin-right: 20px;
  position: relative;
}
@media (max-width: 767px) {
  .coreal-header-bar ul li a {
    padding-right: 10px;
    margin-right: 10px;
  }
}
.coreal-header-bar ul li a::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 20px;
  background: #4768b1;
}
.coreal-header-bar ul li:last-child a {
  padding-right: 0;
  margin-right: 0;
}
.coreal-header-bar ul li:last-child a::before {
  display: none;
}

.coreal-header-2-area {
  position: absolute;
  left: 0;
  top: 0px;
  right: 0;
  padding-top: 40px;
}
.coreal-header-2-area .coreal-header-main-menu ul > li > a {
  line-height: 60px;
}
.coreal-header-2-area .coreal-header-items .coreal-header-btn {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .coreal-header-2-area .coreal-header-items .coreal-header-btn .main-btn {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-header-2-area .coreal-header-items .coreal-header-btn .main-btn {
    display: block;
  }
}
.coreal-header-2-area .coreal-header-items .coreal-header-btn .toggle-btn {
  margin-left: 20px;
}

.coreal-header-area-3 {
  background: #3257a8;
  position: relative;
  z-index: 99;
}
.coreal-header-area-3 .coreal-header-topbar-3 {
  background: #111111;
}
.coreal-header-area-3 .coreal-header-topbar-3 .coreal-header-topbar-box {
  padding-left: 185px;
  padding-right: 185px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-header-area-3 .coreal-header-topbar-3 .coreal-header-topbar-box {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-header-area-3 .coreal-header-topbar-3 .coreal-header-topbar-box {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-area-3 .coreal-header-topbar-3 .coreal-header-topbar-box {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .coreal-header-area-3 .coreal-header-topbar-3 .coreal-header-topbar-box {
    padding-left: 0;
    padding-right: 0;
  }
}
.coreal-header-area-3 .coreal-header-items-3 {
  padding-left: 185px;
  padding-right: 185px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-header-area-3 .coreal-header-items-3 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-header-area-3 .coreal-header-items-3 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-area-3 .coreal-header-items-3 {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .coreal-header-area-3 .coreal-header-items-3 {
    display: none !important;
  }
}

.coreal-header-topbar-info {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-topbar-info {
    display: none;
  }
}
@media (max-width: 767px) {
  .coreal-header-topbar-info {
    display: none;
  }
}
.coreal-header-topbar-info .item {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-header-topbar-info .item.ml-70 {
    margin-left: 20px;
  }
}
.coreal-header-topbar-info .item .icon {
  margin-right: 15px;
}
.coreal-header-topbar-info .item .content span {
  font-size: 14px;
  color: #999;
}
.coreal-header-topbar-info .item .content a {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  display: block;
}

.coreal-header-topbar-brand {
  position: relative;
}
.coreal-header-topbar-brand > a {
  background: #fff;
  display: inline-block;
  line-height: 125px;
  padding: 0 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-header-topbar-brand > a {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-topbar-brand > a {
    padding: 0 30px;
  }
}
@media (max-width: 767px) {
  .coreal-header-topbar-brand > a {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-header-topbar-brand > a {
    padding: 0 30px;
  }
}
.coreal-header-topbar-brand .coreal-live-chat {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #093188;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-topbar-brand .coreal-live-chat {
    display: none;
  }
}
@media (max-width: 767px) {
  .coreal-header-topbar-brand .coreal-live-chat {
    display: none;
  }
}
.coreal-header-topbar-brand .coreal-live-chat a {
  color: #fff;
  font-weight: 700;
  display: block;
}
.coreal-header-topbar-brand .coreal-live-chat a i {
  padding-right: 5px;
}

.coreal-header-topbar-btns .coreal-header-bar {
  position: static;
  padding: 17px 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-header-topbar-btns .coreal-header-bar {
    position: relative;
    left: auto;
    transform: translateY(0);
  }
}
@media (max-width: 767px) {
  .coreal-header-topbar-btns .coreal-header-bar {
    position: relative;
    left: auto;
    transform: translateY(0);
  }
}
@media (max-width: 767px) {
  .coreal-header-topbar-btns .link {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-header-topbar-btns .link {
    display: block;
  }
}
.coreal-header-topbar-btns .link a {
  line-height: 60px;
  padding: 0 32px;
  border: 2px solid #2b2b2b;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  border-radius: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-header-topbar-btns .link a {
    padding: 0 15px;
  }
}
.coreal-header-topbar-btns .link a i {
  padding-right: 5px;
}

.coreal-header-items-3 .coreal-header-main-menu ul li {
  margin-left: 0;
  margin-right: 53px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-header-items-3 .coreal-header-main-menu ul li {
    margin-right: 26px;
  }
}
.coreal-header-items-3 .coreal-header-main-menu ul li a {
  color: #fff;
  line-height: 60px;
}
.coreal-header-items-3 .coreal-header-btns .coreal-select-item .nice-select {
  background: #3257a8;
  border: 0;
  color: #fff;
}
.coreal-header-items-3
  .coreal-header-btns
  .coreal-select-item
  .nice-select::after {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}
.coreal-header-items-3
  .coreal-header-btns
  .coreal-select-item
  .nice-select
  ul
  li {
  display: block;
  color: #777777;
}
.coreal-header-items-3 .coreal-header-btns .coreal-header-search .input-box {
  position: relative;
  padding-left: 20px;
  margin-left: 20px;
}
.coreal-header-items-3
  .coreal-header-btns
  .coreal-header-search
  .input-box::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #3d5fa8;
  height: 16px;
  width: 2px;
}
.coreal-header-items-3 .coreal-header-btns .coreal-header-search .input-box i {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}
.coreal-header-items-3
  .coreal-header-btns
  .coreal-header-search
  .input-box
  input {
  background: transparent;
  border: 0;
  color: #748cc2;
  padding-left: 24px;
}
.coreal-header-items-3
  .coreal-header-btns
  .coreal-header-search
  .input-box
  input::placeholder {
  opacity: 1;
  color: #748cc2;
}

/*===========================
    3.BANNER css 
===========================*/
.coreal-page-banner-area {
  position: relative;
  z-index: 10;
}
.coreal-page-banner-area.border {
  border-bottom: 1px solid #000;
}

.coreal-page-banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0;
}
@media (max-width: 767px) {
  .coreal-page-banner-content {
    display: block;
  }
}
@media (max-width: 767px) {
  .coreal-page-banner-content .heading {
    margin-bottom: 15px;
  }
}
.coreal-page-banner-content .heading .title {
  font-size: 30px;
  margin-bottom: 0;
}
.coreal-page-banner-content nav {
  display: inline-block;
}
.coreal-page-banner-content nav .breadcrumb {
  margin: 0;
  padding: 0;
  background: transparent;
}
.coreal-page-banner-content nav .breadcrumb li {
  font-weight: 700;
}
.coreal-page-banner-content nav .breadcrumb li a {
  color: #3257a8;
}
.coreal-page-banner-content nav .breadcrumb li.active {
  color: #999999;
  font-weight: 400;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 16px;
  color: #e2e2e2;
  content: "/";
  padding-left: 7px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 7px;
    padding-left: 3px;
  }
}

/*===========================
    4. COREAL FOOTER css 
===========================*/
.coreal-footer-area {
  background-image: url(../images/footer-bg.jpg);
  background-position: center;
  background-size: cover;
  padding: 62px 0 87px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.coreal-footer-area::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/brush.png);
  background-size: 100% 100%;
  z-index: -1;
}
.coreal-footer-area.coreal-footer-3-area::before {
  background-image: url(../images/brash-2.png);
}
.coreal-footer-area.coreal-footer-3-area
  .coreal-footer-widget
  .wdiget-title
  .title {
  color: #111111;
}
.coreal-footer-area.coreal-footer-3-area .coreal-footer-contact ul li a {
  color: #777777;
}
.coreal-footer-area.coreal-footer-3-area .coreal-footer-navigation ul li a {
  color: #777777;
}
.coreal-footer-area.coreal-footer-3-area
  .coreal-footer-navigation
  ul
  li
  a:hover {
  color: #3257a8;
}
.coreal-footer-area.coreal-footer-3-area
  .coreal-post-item
  .content
  .meta-author
  span
  a {
  color: #777777;
}
.coreal-footer-area.coreal-footer-3-area .coreal-post-item .title a {
  color: #111111;
}
.coreal-footer-area.coreal-footer-3-area .coreal-post-item .title a:hover {
  color: #3257a8;
}

.coreal-footer-widget {
  margin-top: 30px;
}
.coreal-footer-widget .wdiget-title .title {
  color: #fff;
  margin-bottom: 28px;
  font-size: 20px;
}

.coreal-footer-contact ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-footer-contact ul li {
  line-height: 30px;
}
.coreal-footer-contact ul li a {
  color: #a8a8a8;
  font-size: 14px;
  display: inline-block;
}

.coreal-footer-navigation-item {
  margin-left: -60px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-footer-navigation-item {
    margin-left: -30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-footer-navigation-item {
    margin-left: -0px;
  }
}
@media (max-width: 767px) {
  .coreal-footer-navigation-item {
    margin-left: -0px;
  }
}

.coreal-footer-navigation ul {
  margin: 0;
  padding: 0;
  list-style-position: none;
  margin-right: 45px;
}
.coreal-footer-navigation ul li {
  display: block;
}
.coreal-footer-navigation ul li a {
  color: #a8a8a8;
  font-size: 14px;
}
.coreal-footer-navigation ul li a:hover {
  color: #3257a8;
}
.coreal-footer-navigation ul:last-child {
  margin-right: 0;
}

.coreal-footer-post-item {
  margin-left: -120px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-footer-post-item {
    margin-left: -30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-footer-post-item {
    margin-left: 0px;
  }
}
@media (max-width: 767px) {
  .coreal-footer-post-item {
    margin-left: 0px;
  }
}

.coreal-post-item {
  display: flex;
  align-items: center;
}
.coreal-post-item .thumb {
  min-width: 70px;
  max-width: 70px;
  margin-right: 20px;
}
.coreal-post-item .content .meta-author span {
  font-size: 14px;
  color: #555;
}
.coreal-post-item .content .meta-author span a {
  color: #999;
}
.coreal-post-item .title {
  color: #fff;
  font-size: 18px;
}
.coreal-post-item .title a {
  color: #fff;
}
.coreal-post-item .title a:hover {
  color: #3257a8;
}

.coreal-footer-gallery ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-footer-gallery ul li {
  display: inline-block;
  margin-top: 15px;
  margin-right: 11px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-footer-gallery ul li {
    width: 56px;
  }
}
@media (max-width: 767px) {
  .coreal-footer-gallery ul li {
    width: 66px;
  }
}

.coreal-copyright-area {
  background: #0a0a0a;
  border-top: 1px solid #394245;
  padding: 20px 0;
  overflow: hidden;
}
@media (max-width: 767px) {
  .coreal-copyright-area .coreal-copyright-box {
    text-align: center;
  }
}
.coreal-copyright-area.coreal-copyright-2-area {
  background: linear-gradient(0deg, white 0%, #f6f6f6 100%);
  border-top: 0;
}
.coreal-copyright-area.coreal-copyright-2-area .coreal-copyright-item p {
  font-size: 14px;
}
.coreal-copyright-area.coreal-copyright-2-area .coreal-copyright-item p a {
  color: #111111;
}
.coreal-copyright-area.coreal-copyright-area-3 {
  background: #fff;
  border-top: 0;
}
.coreal-copyright-area.coreal-copyright-area-3 .coreal-copyright-item p {
  color: #777777;
}
.coreal-copyright-area.coreal-copyright-area-3 .coreal-copyright-item p a {
  color: #777777;
}
.coreal-copyright-area.coreal-copyright-area-3
  .coreal-copyright-social
  ul
  li
  a {
  color: #777777;
}
.coreal-copyright-area.coreal-copyright-area-3
  .coreal-copyright-social
  ul
  li
  a:hover {
  color: #3257a8;
}

.coreal-copyright-item p {
  color: #999;
  margin-bottom: 0;
}
.coreal-copyright-item p a {
  font-weight: 700;
  color: #fff;
}

.coreal-copyright-social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-copyright-social ul li {
  display: inline-block;
}
.coreal-copyright-social ul li a {
  color: #959595;
  margin-left: 20px;
}
@media (max-width: 767px) {
  .coreal-copyright-social ul li a {
    margin: 0 10px;
  }
}
.coreal-copyright-social ul li a:hover {
  color: #3257a8;
}

.coreal-footer-2-area {
  background: linear-gradient(0deg, white 0%, #f6f6f6 100%);
}

.footer-about-widget {
  padding-top: 92px;
  padding-bottom: 135px;
  position: relative;
  z-index: 10;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-about-widget {
    padding-top: 0;
  }
}
@media (max-width: 767px) {
  .footer-about-widget {
    padding-top: 0;
  }
}
.footer-about-widget::before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 130%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer-about-widget::before {
    width: 120%;
  }
}
.footer-about-widget .title {
  font-size: 24px;
  margin-bottom: 33px;
}
.footer-about-widget p {
  font-size: 20px;
  line-height: 36px;
}
.footer-about-widget ul {
  margin: 0;
  padding: 26px 0 0;
  list-style-type: none;
}
.footer-about-widget ul li {
  display: inline-block;
}
.footer-about-widget ul li a {
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #eee;
  font-size: 14px;
  color: #777777;
  border-radius: 10px;
  margin-right: 7px;
}
.footer-about-widget ul li a:hover {
  background: #3257a8;
  color: #fff;
}

.footer-links-widget {
  padding-left: 110px;
  padding-top: 90px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer-links-widget {
    padding-left: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-links-widget {
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .footer-links-widget {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-links-widget {
    padding-left: 0px;
    padding-right: 200px;
  }
}
.footer-links-widget .item .title {
  font-size: 24px;
  margin-bottom: 32px;
}
.footer-links-widget .item ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-links-widget .item ul li a {
  color: #777777;
  margin-bottom: 20px;
  display: inline-block;
}
.footer-links-widget .item ul li a:hover {
  color: #3257a8;
}

.footer-post-widget {
  padding-top: 90px;
  padding-left: 95px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer-post-widget {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-post-widget {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .footer-post-widget {
    padding-left: 0;
  }
}
.footer-post-widget .widget-title {
  padding-bottom: 32px;
}
.footer-post-widget .widget-title .title {
  font-size: 24px;
}

.footer-post-box .footer-post-item {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.footer-post-box .footer-post-item .thumb {
  max-width: 70px;
  min-width: 70px;
  margin-right: 25px;
}
.footer-post-box .footer-post-item .thumb img {
  border-radius: 10px;
}
.footer-post-box .footer-post-item .content .title {
  font-size: 16px;
  margin-bottom: 4px;
}
.footer-post-box .footer-post-item .content .title a {
  color: #111111;
}
.footer-post-box .footer-post-item .content .title a:hover {
  color: #3257a8;
}
.footer-post-box .footer-post-item .content .meta-item {
  display: flex;
  font-size: 14px;
}
.footer-post-box .footer-post-item .content .meta-item .meta-author {
  padding-right: 15px;
  margin-right: 15px;
  position: relative;
}
.footer-post-box .footer-post-item .content .meta-item .meta-author::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 15px;
  background: #e9e9e9;
}
.footer-post-box .footer-post-item .content .meta-item .meta-author span {
  color: #d9d9d9;
}
.footer-post-box .footer-post-item .content .meta-item .meta-author span a {
  color: #111111;
}

/*===========================
    BACK TO TOP CSS
===========================*/
.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: none;
  z-index: 999999;
}
.back-to-top a {
  color: #fff;
  background: #3257a8;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  display: inline-block;
  border-radius: 15px;
}

/*===========================
    5. COREAL LISTING DETAILS css 
===========================*/
.coreal-listing-details-topbar-area {
  background: #f8f8f8;
  padding-top: 100px;
  padding-bottom: 180px;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listing-details-topbar .coreal-listing-details-heading {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-listing-details-topbar .coreal-listing-details-heading {
    margin-bottom: 30px;
  }
}
.coreal-listing-details-topbar .coreal-listing-details-heading .title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: -5px;
}
@media (max-width: 767px) {
  .coreal-listing-details-topbar .coreal-listing-details-heading .title {
    margin-bottom: 0;
    font-size: 28px;
  }
}
.coreal-listing-details-topbar .coreal-listing-details-heading p {
  font-size: 14px;
  color: #777;
  margin-bottom: 0;
}
.coreal-listing-details-topbar .coreal-listing-details-heading p i {
  color: #3257a8;
  padding-right: 6px;
}

.coreal-listing-details-price-bar {
  width: 305px;
  background: #fff;
  border-radius: 15px;
  padding: 14px 18px 14px 20px;
  box-shadow: 0 15px 32px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}
@media (max-width: 767px) {
  .coreal-listing-details-price-bar {
    width: 280px;
  }
}
.coreal-listing-details-price-bar span {
  font-size: 30px;
  font-weight: 700;
  color: #3257a8;
}
.coreal-listing-details-price-bar span span {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.coreal-listing-details-price-bar span.item-2 {
  color: #111111;
  font-size: 24px;
  margin-top: -4px;
}
.coreal-listing-details-price-bar p {
  background: #3257a8;
  color: #fff;
  text-align: center;
  line-height: 55px;
  width: 100%;
  position: absolute;
  left: -98px;
  top: 21%;
  transform: rotate(-90deg) translateY(-50%);
}
@media (max-width: 767px) {
  .coreal-listing-details-price-bar p {
    left: -88px;
  }
}

.coreal-listing-details-slider .listing-details-slider-active .col-lg-4 {
  padding-left: 40px;
  padding-right: 40px;
}
.coreal-listing-details-slider
  .listing-details-slider-active
  .slick-center
  .coreal-listing-details-slider-thumb
  .open-time {
  opacity: 1;
  visibility: visible;
}

.coreal-listing-details-slider-thumb {
  position: relative;
}
.coreal-listing-details-slider-thumb img {
  width: 100%;
}
.coreal-listing-details-slider-thumb .open-time {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 0 14px;
  line-height: 25px;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
}
.coreal-listing-details-slider-thumb .open-time a {
  color: #3257a8;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  position: relative;
}
.coreal-listing-details-slider-thumb .open-time a::before {
  position: absolute;
  content: "";
  left: -14px;
  top: 100%;
  width: 0;
  height: 0;
  border-top: 8px solid #fff;
  border-right: 8px solid transparent;
}

.coreal-listing-details-box {
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 15px 32px 0px rgba(0, 0, 0, 0.1);
  padding-top: 32px;
  padding-bottom: 22px;
}
.coreal-listing-details-box .title {
  font-size: 20px;
  margin-bottom: 0;
  padding-top: 12px;
  margin-bottom: -4px;
}

.coreal-listing-details-main-box {
  position: relative;
}
.coreal-listing-details-main-box::before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  top: -80px;
  width: 100%;
  height: 80px;
  background: #fff;
  border-radius: 20px 20px 0 0;
}

.coreal-listing-details-title .title {
  font-size: 24px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 18px;
  margin-bottom: 30px;
}

.coreal-listing-details-item ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-listing-details-item ul li {
  font-size: 14px;
  font-weight: 700;
  color: #111111;
  padding-bottom: 5px;
}
.coreal-listing-details-item ul li span {
  color: #777777;
  font-weight: 400;
  padding-left: 5px;
}

.coreal-listing-details-checkbox {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.coreal-listing-details-checkbox li {
  display: inline-block;
  margin-right: 29px;
  line-height: 15px;
}
@media (max-width: 767px) {
  .coreal-listing-details-checkbox li {
    margin-right: 15px;
  }
}

.coreal-listing-details-checkbox li label {
  color: #333;
  font-size: 14px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 400;
}

.coreal-listing-details-checkbox li input[type="checkbox"] {
  display: none;
}

.coreal-listing-details-checkbox li input[type="checkbox"] + label span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: -1px 15px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #d2d2d2;
  position: relative;
}

.coreal-listing-details-checkbox li input[type="checkbox"] + label span:before {
  color: #fff;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 8px;
  position: absolute;
  text-align: center;
  left: 1px;
  top: -3px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.coreal-listing-details-checkbox
  li
  input[type="checkbox"]:checked
  + label
  span {
  background: #3257a8;
  border-color: #3257a8;
}

.coreal-listing-details-checkbox
  li
  input[type="checkbox"]:checked
  + label
  span:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.coreal-listing-details-checkbox li input[type="checkbox"] + label span,
.coreal-listing-details-checkbox
  li
  input[type="checkbox"]:checked
  + label
  span {
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.coreal-listing-details-checkbox
  li
  input[type="checkbox"]
  + label
  span::before {
  color: #fff;
}

.coreal-listing-details-checkbox li input[type="checkbox"] + label span {
  border-radius: 0;
}

.coreal-listing-details-checkbox
  li
  input[type="checkbox"]:checked
  + label
  span {
  background: #3257a8;
}

.coreal-listing-details-attatchment-doc {
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  padding-left: 90px;
  position: relative;
  background: #fafafa;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-top: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-listing-details-attatchment-doc {
    padding-left: 80px;
  }
}
@media (max-width: 767px) {
  .coreal-listing-details-attatchment-doc {
    padding-left: 70px;
  }
}
.coreal-listing-details-attatchment-doc a {
  display: inline-block;
  line-height: 60px;
  font-size: 14px;
  font-weight: 700;
  color: #111111;
}
.coreal-listing-details-attatchment-doc i {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.coreal-listing-details-attatchment-doc .icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  line-height: 60px;
  text-align: center;
  border-right: 1px solid #e5e5e5;
  background: #fff;
  border-radius: 14px 0 0 14px;
}
.coreal-listing-details-attatchment-doc:hover {
  background: #3257a8;
}
.coreal-listing-details-attatchment-doc:hover a {
  color: #fff;
}
.coreal-listing-details-attatchment-doc:hover i {
  color: #fff;
}

.coreal-listing-details-tab-box .accordion-item {
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  margin-bottom: 15px;
  overflow: hidden;
}
.coreal-listing-details-tab-box
  .accordion-item
  .accordion-header
  .accordion-button {
  padding: 0 30px 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #3257a8;
  position: relative;
}
@media (max-width: 767px) {
  .coreal-listing-details-tab-box
    .accordion-item
    .accordion-header
    .accordion-button {
    padding-left: 50px;
    padding-right: 0;
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-listing-details-tab-box
    .accordion-item
    .accordion-header
    .accordion-button {
    padding-left: 40px;
    padding-right: 30px;
    display: flex;
  }
}
.coreal-listing-details-tab-box
  .accordion-item
  .accordion-header
  .accordion-button::before {
  position: absolute;
  content: "\f067";
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  color: #fff;
}
.coreal-listing-details-tab-box
  .accordion-item
  .accordion-header
  .accordion-button
  span {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  padding: 20px 0;
  color: #fff;
  letter-spacing: -0.5px;
  display: block;
}
@media (max-width: 767px) {
  .coreal-listing-details-tab-box
    .accordion-item
    .accordion-header
    .accordion-button
    span {
    padding-right: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-listing-details-tab-box
    .accordion-item
    .accordion-header
    .accordion-button
    span {
    padding-right: 50px;
  }
}
.coreal-listing-details-tab-box
  .accordion-item
  .accordion-header
  .accordion-button
  .coreal-listing-details-item {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
}
@media (max-width: 767px) {
  .coreal-listing-details-tab-box
    .accordion-item
    .accordion-header
    .accordion-button
    .coreal-listing-details-item {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-listing-details-tab-box
    .accordion-item
    .accordion-header
    .accordion-button
    .coreal-listing-details-item {
    display: block;
  }
}
.coreal-listing-details-tab-box
  .accordion-item
  .accordion-header
  .accordion-button
  .coreal-listing-details-item
  p {
  display: inline-block;
  margin-left: 12px;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
}
.coreal-listing-details-tab-box
  .accordion-item
  .accordion-header
  .accordion-button.collapsed {
  background: #fff;
}
.coreal-listing-details-tab-box
  .accordion-item
  .accordion-header
  .accordion-button.collapsed::before {
  color: #3257a8;
  content: "\f068";
}
.coreal-listing-details-tab-box
  .accordion-item
  .accordion-header
  .accordion-button.collapsed
  span {
  color: #111111;
}
.coreal-listing-details-tab-box .accordion-item .accordion-body {
  padding: 60px 60px 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-listing-details-tab-box .accordion-item .accordion-body {
    padding: 30px 30px 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listing-details-tab-box .accordion-item .accordion-body {
    padding: 30px 30px 10px;
  }
}
@media (max-width: 767px) {
  .coreal-listing-details-tab-box .accordion-item .accordion-body {
    padding: 30px 30px 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-listing-details-tab-box .accordion-item .accordion-body .thumb {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listing-details-tab-box .accordion-item .accordion-body .thumb {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-listing-details-tab-box .accordion-item .accordion-body .thumb {
    margin-bottom: 30px;
  }
}
.coreal-listing-details-tab-box.coreal-faq-accordion
  .accordion-header
  .accordion-button {
  background: #fff;
  padding: 0 30px 0 30px;
}
.coreal-listing-details-tab-box.coreal-faq-accordion
  .accordion-header
  .accordion-button::before {
  right: 30px;
  left: auto;
  color: #111111;
}
@media (max-width: 767px) {
  .coreal-listing-details-tab-box.coreal-faq-accordion
    .accordion-header
    .accordion-button::before {
    right: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-listing-details-tab-box.coreal-faq-accordion
    .accordion-header
    .accordion-button::before {
    right: 30px;
  }
}
.coreal-listing-details-tab-box.coreal-faq-accordion
  .accordion-header
  .accordion-button
  span {
  color: #111111;
}
.coreal-listing-details-tab-box.coreal-faq-accordion .accordion-item {
  border: 2px solid #ebebeb;
}
.coreal-listing-details-tab-box.coreal-faq-accordion
  .accordion-item
  .accordion-body {
  padding: 0 30px 10px;
  margin-top: -8px;
}

.coreal-listing-details-play-box {
  margin-top: 40px;
  position: relative;
}
.coreal-listing-details-play-box #coreal-viewer {
  width: 100%;
  height: 500px;
}
.coreal-listing-details-play-box .play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.coreal-listing-details-play-box .play-btn a {
  display: inline-block;
  height: 120px;
  width: 120px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 120px;
  color: #3257a8;
  font-size: 18px;
}
@media (max-width: 767px) {
  .coreal-listing-details-play-box .play-btn a {
    height: 70px;
    width: 70px;
    line-height: 70px;
    font-size: 16px;
  }
}

.coreal-listing-details-map-box {
  position: relative;
}
.coreal-listing-details-map-box.mt-45 {
  height: 98%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listing-details-map-box.mt-45 {
    display: none;
  }
}
@media (max-width: 767px) {
  .coreal-listing-details-map-box.mt-45 {
    display: none;
  }
}
.coreal-listing-details-map-box.mt-45 #coreal-map {
  height: 100%;
}
.coreal-listing-details-map-box img {
  border-radius: 15px;
}
.coreal-listing-details-map-box .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.coreal-listing-details-map-box .icon i {
  font-size: 80px;
  color: #3257a8;
}
@media (max-width: 767px) {
  .coreal-listing-details-map-box .icon i {
    font-size: 50px;
  }
}
.coreal-listing-details-map-box #coreal-map {
  width: 100%;
  height: 490px;
}
.coreal-listing-details-map-box #coreal-map a.mapboxgl-ctrl-logo {
  background-image: none;
}
.coreal-listing-details-map-box
  #coreal-map
  .mapboxgl-ctrl-attrib.mapboxgl-compact {
  display: none;
}
.coreal-listing-details-map-box #coreal-map .marker {
  background-image: url("../images/marker-map.png");
  background-size: 100% 100%;
  width: 60px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
}
.coreal-listing-details-map-box #coreal-map .mapboxgl-popup {
  max-width: 200px;
}
.coreal-listing-details-map-box #coreal-map .mapboxgl-popup-content {
  text-align: center;
}

.coreal-listing-details-nearby .coreal-listing-details-nearby-item {
  position: relative;
  margin-bottom: 9px;
  padding-left: 40px;
  padding-bottom: 12px;
}
.coreal-listing-details-nearby .icon {
  font-size: 18px;
  color: #3257a8;
  position: absolute;
  left: 0;
  top: 0;
}
.coreal-listing-details-nearby .item {
  align-items: center;
  display: flex;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-listing-details-nearby .item {
    display: block;
  }
}
@media (max-width: 767px) {
  .coreal-listing-details-nearby .item {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-listing-details-nearby .item {
    display: flex;
  }
}
.coreal-listing-details-nearby .item ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
@media (max-width: 767px) {
  .coreal-listing-details-nearby .item ul {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-listing-details-nearby .item ul {
    margin-right: 50px;
  }
}
.coreal-listing-details-nearby .item ul li {
  display: inline-block;
  color: #3257a8;
  padding-bottom: 3px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listing-details-nearby .item ul li {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .coreal-listing-details-nearby .item ul li {
    font-size: 14px;
    display: block;
  }
}
.coreal-listing-details-nearby .item ul li span {
  color: #777777;
}
.coreal-listing-details-nearby .item ul li i {
  padding-right: 4px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listing-details-nearby .item ul li i {
    font-size: 12px;
  }
}

.coreal-listing-details-properties-item {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  background: #fff;
}
@media (max-width: 767px) {
  .coreal-listing-details-properties-item {
    margin-bottom: 0px;
    margin-top: 30px;
  }
}
.coreal-listing-details-properties-item .thumb {
  position: relative;
  overflow: hidden;
  border-radius: 14px 14px 0 0;
  position: relative;
  z-index: 10;
}
.coreal-listing-details-properties-item .thumb img {
  width: 100%;
  position: relative;
  z-index: -2;
  transition: all linear 0.5s;
}
.coreal-listing-details-properties-item .thumb::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  z-index: -1;
}
.coreal-listing-details-properties-item .thumb > i {
  position: absolute;
  right: 25px;
  top: 25px;
  color: #fff;
}
.coreal-listing-details-properties-item .thumb a {
  position: absolute;
  left: 30px;
  bottom: 30px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  line-height: 36px;
  color: #fff;
  font-size: 14px;
  padding: 0 10px;
}
.coreal-listing-details-properties-item .thumb a span {
  font-weight: 700;
}
.coreal-listing-details-properties-item .thumb a i {
  padding-right: 2px;
}
.coreal-listing-details-properties-item .thumb a:hover {
  background: #3257a8;
  border-color: #3257a8;
}
.coreal-listing-details-properties-item .content {
  border: 2px solid #ededed;
  border-top: 0;
  border-radius: 0 0 14px 14px;
  padding: 30px;
  position: relative;
  z-index: 11;
}
.coreal-listing-details-properties-item .content .title {
  font-size: 24px;
  color: #3257a8;
  margin-bottom: 11px;
}
.coreal-listing-details-properties-item .content p {
  margin-bottom: 2px;
}
.coreal-listing-details-properties-item .content p a {
  font-size: 18px;
  color: #111111;
  line-height: 26px;
}
.coreal-listing-details-properties-item .content p a:hover {
  color: #3257a8;
}
.coreal-listing-details-properties-item .content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-listing-details-properties-item .content ul li {
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #dddddd;
  line-height: 12px;
  font-size: 14px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-listing-details-properties-item .content ul li {
    padding-right: 5px;
    margin-right: 5px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listing-details-properties-item .content ul li {
    padding-right: 5px;
    margin-right: 5px;
    font-size: 12px;
  }
}
.coreal-listing-details-properties-item .content ul li i {
  color: #3257a8;
  padding-right: 6px;
}
.coreal-listing-details-properties-item .content ul li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: 0;
}
.coreal-listing-details-properties-item .content .author {
  position: absolute;
  right: 22px;
  top: -25px;
}
.coreal-listing-details-properties-item .content .author img {
  border-radius: 12px;
  border: 3px solid #fff;
}
.coreal-listing-details-properties-item:hover .thumb img {
  transform: scale(1.2);
}

.coreal-listing-details-comments-box .details-comments-item {
  border-top: 1px solid #ededed;
  display: flex;
  padding-bottom: 10px;
  padding-top: 40px;
}
@media (max-width: 767px) {
  .coreal-listing-details-comments-box .details-comments-item {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-listing-details-comments-box .details-comments-item {
    display: flex;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-listing-details-comments-box .details-comments-item.ml-130 {
    margin-left: 50px;
  }
}
@media (max-width: 767px) {
  .coreal-listing-details-comments-box .details-comments-item.ml-130 {
    margin-left: 30px;
  }
}
.coreal-listing-details-comments-box .details-comments-item .thumb {
  min-width: 100px;
  max-width: 100px;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .coreal-listing-details-comments-box .details-comments-item .thumb {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-listing-details-comments-box .details-comments-item .thumb {
    margin-bottom: 0px;
  }
}
.coreal-listing-details-comments-box .details-comments-item .content {
  padding-right: 175px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-listing-details-comments-box .details-comments-item .content {
    padding-right: 110px;
  }
}
@media (max-width: 767px) {
  .coreal-listing-details-comments-box .details-comments-item .content {
    padding-right: 0px;
  }
}
.coreal-listing-details-comments-box .details-comments-item .content .title {
  font-size: 18px;
  margin-bottom: 0;
}
.coreal-listing-details-comments-box .details-comments-item .content span {
  padding-bottom: 16px;
  display: inline-block;
  font-size: 12px;
}
.coreal-listing-details-comments-box .details-comments-item .content p {
  font-size: 14px;
  line-height: 24px;
}
.coreal-listing-details-comments-box .details-comments-item .content a {
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #ededed;
  display: inline-block;
  line-height: 36px;
  color: #777777;
  border-radius: 10px;
  padding: 0 20px;
  font-size: 14px;
}
@media (max-width: 767px) {
  .coreal-listing-details-comments-box .details-comments-item .content a {
    position: static;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-listing-details-comments-box .details-comments-item .content a {
    position: absolute;
    margin-bottom: 0;
  }
}
.coreal-listing-details-comments-box .details-comments-item .content a:hover {
  background: #3257a8;
  color: #fff;
  border-color: #3257a8;
}
.coreal-listing-details-comments-box .details-comments-item:first-child {
  border-top: 0;
  padding-top: 0;
}

.coreal-listing-details-form-box {
  padding: 52px 60px 40px;
  background: #f8f8f8;
  border-radius: 15px;
}
@media (max-width: 767px) {
  .coreal-listing-details-form-box {
    padding: 22px 30px 10px;
    margin-bottom: 45px;
  }
}
.coreal-listing-details-form-box .top-bar {
  padding-bottom: 10px;
}
.coreal-listing-details-form-box .top-bar ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-listing-details-form-box .top-bar ul li {
  display: inline-block;
}
.coreal-listing-details-form-box .top-bar p {
  font-size: 14px;
  padding-top: 5px;
}
.coreal-listing-details-form-box .top-bar .coreal-rating {
  display: block;
}
.coreal-listing-details-form-box .top-bar .coreal-rating .rating {
  border: none;
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
}
.coreal-listing-details-form-box .top-bar .coreal-rating .rating > input {
  display: none;
}
.coreal-listing-details-form-box
  .top-bar
  .coreal-rating
  .rating
  > label:before {
  margin: 5px;
  font-size: 18px;
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  content: "\f005";
}
.coreal-listing-details-form-box
  .top-bar
  .coreal-rating
  .rating
  > .half:before {
  content: "\f089";
  position: absolute;
}
.coreal-listing-details-form-box .top-bar .coreal-rating .rating > label {
  color: #999;
}
.coreal-listing-details-form-box
  .top-bar
  .coreal-rating
  .rating
  > input:checked
  ~ label,
.coreal-listing-details-form-box
  .top-bar
  .coreal-rating
  .rating:not(:checked)
  > label:hover,
.coreal-listing-details-form-box
  .top-bar
  .coreal-rating
  .rating:not(:checked)
  > label:hover
  ~ label {
  color: #ffd700;
  font-weight: 700;
}
.coreal-listing-details-form-box
  .top-bar
  .coreal-rating
  .rating
  > input:checked
  + label:hover,
.coreal-listing-details-form-box
  .top-bar
  .coreal-rating
  .rating
  > input:checked
  ~ label:hover,
.coreal-listing-details-form-box
  .top-bar
  .coreal-rating
  .rating
  > label:hover
  ~ input:checked
  ~ label,
.coreal-listing-details-form-box
  .top-bar
  .coreal-rating
  .rating
  > input:checked
  ~ label:hover
  ~ label {
  color: #ffd700;
  font-weight: 700;
}
.coreal-listing-details-form-box .input-box {
  position: relative;
  margin-bottom: 20px;
}
.coreal-listing-details-form-box .input-box i {
  position: absolute;
  right: 30px;
  top: 23px;
  color: #3257a8;
  font-size: 14px;
}
.coreal-listing-details-form-box .input-box textarea {
  border: 0;
  border-radius: 15px;
  padding: 20px 30px;
  font-size: 14px;
  color: #999;
  height: 150px;
  width: 100%;
  resize: none;
}
.coreal-listing-details-form-box .input-box textarea::placeholder {
  opacity: 1;
  color: #999;
}
.coreal-listing-details-form-box .input-box input {
  border: 0;
  border-radius: 15px;
  padding: 0px 30px;
  font-size: 14px;
  color: #999;
  height: 60px;
  width: 100%;
}
.coreal-listing-details-form-box .input-box input::placeholder {
  opacity: 1;
  color: #999;
}

.coreal-listing-details-main-area {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .listing-details-sidebar {
    margin-top: 45px;
  }
}

.sidebar-widget {
  padding: 32px 40px 40px;
  border: 2px solid #ededed;
  border-radius: 15px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sidebar-widget {
    padding: 12px 20px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget {
    padding: 12px 20px 20px;
  }
}
@media (max-width: 767px) {
  .sidebar-widget {
    padding: 12px 20px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sidebar-widget {
    padding: 12px 20px 20px;
  }
}
.sidebar-widget .sidebar-widget-title {
  border-bottom: 1px solid #ededed;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sidebar-widget .sidebar-widget-title.pt-15 {
    padding-top: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget .sidebar-widget-title.pt-15 {
    padding-top: 40px;
  }
}
@media (max-width: 767px) {
  .sidebar-widget .sidebar-widget-title.pt-15 {
    padding-top: 40px;
  }
}
.sidebar-widget .sidebar-widget-title .title {
  font-size: 20px;
}
.sidebar-widget .sidebar-widget-title img {
  height: 30px;
}

.agent-details {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.agent-details .thumb {
  margin-right: 30px;
  border-radius: 14px;
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .agent-details .thumb {
    margin-right: 15px;
  }
}
.agent-details .content .title {
  font-size: 18px;
  margin-bottom: 0;
}
.agent-details .content span {
  font-size: 14px;
  color: #3257a8;
}

.agent-contact-details {
  padding-bottom: 13px;
}
.agent-contact-details ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.agent-contact-details ul li {
  font-size: 18px;
  margin-bottom: 16px;
}
.agent-contact-details ul li a {
  color: #777777;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .agent-contact-details ul li {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .agent-contact-details ul li {
    font-size: 15px;
  }
}
.agent-contact-details ul li i {
  height: 50px;
  width: 50px;
  text-align: center;
  border: 2px solid #e1e1e1;
  color: #3257a8;
  line-height: 46px;
  border-radius: 10px;
  margin-right: 14px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}

.agent-contact-info {
  border-top: 1px solid #ededed;
  padding-top: 38px;
}
.agent-contact-info a {
  background: #3257a8;
  border: 2px solid #3257a8;
  display: inline-block;
  line-height: 60px;
  padding: 0 40px;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .agent-contact-info a {
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .agent-contact-info a {
    margin-bottom: 20px;
  }
}
.agent-contact-info a.chat-box {
  background: transparent;
  color: #777777;
  border-color: #e7e7e7;
  padding: 0;
  width: 60px;
  text-align: center;
  margin-left: 12px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}

.listing-details-find {
  padding-bottom: 20px;
}

.listing-details-find-box .input-box {
  margin-bottom: 20px;
  position: relative;
}
.listing-details-find-box .input-box i {
  position: absolute;
  right: 30px;
  top: 22px;
  color: #777777;
}
.listing-details-find-box .input-box input {
  width: 100%;
  height: 60px;
  padding-left: 30px;
  border-radius: 10px;
  border: 2px solid #ededed;
  font-size: 14px;
  color: #999;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
}
.listing-details-find-box .input-box input::placeholder {
  opacity: 1;
  color: #999;
}
.listing-details-find-box .input-box textarea {
  width: 100%;
  height: 200px;
  padding-left: 30px;
  padding-top: 10px;
  border-radius: 10px;
  border: 2px solid #ededed;
  font-size: 14px;
  color: #999;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
  resize: none;
}
.listing-details-find-box .input-box textarea::placeholder {
  opacity: 1;
  color: #999;
}
.listing-details-find-box .input-box .coreal-select-item .nice-select {
  width: 100%;
  height: 60px;
  float: none;
  border: 2px solid #ededed;
  border-radius: 10px;
  padding-left: 30px;
  line-height: 55px;
  color: #999;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
}
.listing-details-find-box .input-box .coreal-select-item .nice-select .list {
  width: 100%;
}
.listing-details-find-box .input-box .coreal-select-item .nice-select::after {
  right: 30px;
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
}
.listing-details-find-box .input-box button {
  width: 100%;
  background: #3257a8;
  color: #fff;
  border: 2px solid #3257a8;
  line-height: 55px;
  text-align: center;
  border-radius: 10px;
}

.listing-details-featured-properties {
  padding-bottom: 30px;
}

.listing-details-featured-properties-item .thumb {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  overflow: hidden;
  z-index: 10;
}
.listing-details-featured-properties-item .thumb img {
  width: 100%;
  transition: all linear 0.5s;
  z-index: -2;
  position: relative;
}
.listing-details-featured-properties-item .thumb::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  z-index: -1;
}
.listing-details-featured-properties-item .thumb > i {
  position: absolute;
  right: 25px;
  bottom: 25px;
  color: #fff;
}
.listing-details-featured-properties-item .thumb .properties-price {
  position: absolute;
  left: 30px;
  bottom: 10px;
}
.listing-details-featured-properties-item .thumb .properties-price span {
  color: #fff;
  font-size: 14px;
}
.listing-details-featured-properties-item .thumb .properties-price .title {
  color: #fff;
  font-size: 24px;
}
.listing-details-featured-properties-item .content {
  position: relative;
  padding-top: 20px;
}
.listing-details-featured-properties-item .content p {
  margin-bottom: 2px;
}
.listing-details-featured-properties-item .content p a {
  font-size: 18px;
  color: #111111;
}
.listing-details-featured-properties-item .content p a:hover {
  color: #3257a8;
}
.listing-details-featured-properties-item .content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.listing-details-featured-properties-item .content ul li {
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #dddddd;
  line-height: 12px;
  font-size: 14px;
}
.listing-details-featured-properties-item .content ul li i {
  color: #3257a8;
  padding-right: 6px;
}
.listing-details-featured-properties-item .content ul li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: 0;
}
.listing-details-featured-properties-item.listing-item .thumb {
  border-radius: 0;
}
.listing-details-featured-properties-item:hover .thumb img {
  transform: scale(1.2);
}

/*===========================
    6. COREAL HERO css 
===========================*/
.coreal-hero-1-area {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.coreal-hero-content {
  margin-right: -40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-hero-content {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .coreal-hero-content {
    margin-right: 0;
  }
}
.coreal-hero-content .title {
  font-size: 72px;
  line-height: 82px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-hero-content .title {
    font-size: 54px;
    line-height: 64px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-hero-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .coreal-hero-content .title {
    font-size: 38px;
    line-height: 44px;
  }
}
.coreal-hero-content .title span {
  color: #3257a8;
  position: relative;
  z-index: 10;
}
.coreal-hero-content .title span::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 245px;
  height: 120px;
  background-image: url(../images/hero-line.png);
  z-index: -1;
}
@media (max-width: 767px) {
  .coreal-hero-content .title span::before {
    display: none;
  }
}
.coreal-hero-content > span {
  font-size: 18px;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-thumb {
    display: none;
  }
}
@media (max-width: 767px) {
  .hero-thumb {
    display: none;
  }
}

.coreal-hero-search {
  margin-top: 43px;
}
.coreal-hero-search .input-box {
  border: 2px solid #ededed;
  border-radius: 15px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  width: 650px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-hero-search .input-box {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .coreal-hero-search .input-box {
    width: 100%;
    display: block;
    padding: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-hero-search .input-box {
    width: 100%;
    display: flex;
    padding: 8px;
  }
}
.coreal-hero-search .input-box input {
  border: 0;
  line-height: 50px;
  width: 360px;
  padding-left: 30px;
  color: #999;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-hero-search .input-box input {
    width: 300px;
  }
}
@media (max-width: 767px) {
  .coreal-hero-search .input-box input {
    width: 250px;
    padding-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-hero-search .input-box input {
    width: 250px;
    padding-left: 30px;
  }
}
.coreal-hero-search .input-box input::placeholder {
  opacity: 1;
  color: #999;
}
.coreal-hero-search .input-box .coreal-select-item .nice-select {
  float: none;
  padding: 0;
  border: 0;
  font-size: 14px;
  font-weight: 700;
  color: #999;
  line-height: 50px;
}
@media (max-width: 767px) {
  .coreal-hero-search .input-box .coreal-select-item .nice-select {
    padding-left: 25px;
    margin-bottom: 20px;
  }
}
.coreal-hero-search .input-box .coreal-select-item .nice-select::after {
  display: none;
}
.coreal-hero-search .input-box .coreal-select-item .nice-select::before {
  position: absolute;
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  left: -18px;
  top: 0;
  font-size: 14px;
  color: #999999;
}
@media (max-width: 767px) {
  .coreal-hero-search .input-box .coreal-select-item .nice-select::before {
    left: 0;
  }
}
.coreal-hero-search .input-box .coreal-select-item .nice-select .list {
  left: -30px;
}
@media (max-width: 767px) {
  .coreal-hero-search .input-box .coreal-select-item .nice-select .list {
    left: 0;
  }
}
.coreal-hero-search .input-box .coreal-select-item .nice-select .list li {
  display: block;
}
.coreal-hero-search .input-box button {
  background: #3257a8;
  color: #fff;
  border: 2px solid #3257a8;
  line-height: 46px;
  padding: 0 30px;
  border-radius: 15px;
}
.coreal-hero-search ul {
  margin: 0;
  padding: 14px 0 0;
  list-style-type: none;
}
.coreal-hero-search ul > li {
  display: inline-block;
}
.coreal-hero-search ul > li a {
  font-size: 14px;
  font-weight: 700;
  color: #b3b3b3;
  margin-right: 50px;
}
@media (max-width: 767px) {
  .coreal-hero-search ul > li a {
    margin-right: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-hero-search ul > li a {
    margin-right: 30px;
  }
}
.coreal-hero-search ul > li a.active,
.coreal-hero-search ul > li a:hover {
  color: #3257a8;
}

.hero-thumb {
  margin-right: -80px;
  text-align: right;
}

.coreal-hero-2-area {
  background: #111;
  padding-top: 130px;
  position: relative;
  z-index: 10;
}
.coreal-hero-2-area::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../images/hero-dot.png);
  background-position: top center;
  background-size: 95% 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.coreal-hero-content-2 {
  padding-top: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-hero-content-2 {
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-hero-content-2 {
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .coreal-hero-content-2 {
    padding-bottom: 100px;
  }
}
.coreal-hero-content-2 > span {
  font-size: 18px;
  color: #a1a1a1;
  display: inline-block;
}
.coreal-hero-content-2 .title {
  color: #fff;
  font-size: 72px;
  line-height: 82px;
  padding-top: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-hero-content-2 .title {
    font-size: 54px;
    line-height: 68px;
  }
}
@media (max-width: 767px) {
  .coreal-hero-content-2 .title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-hero-content-2 .title {
    font-size: 48px;
    line-height: 58px;
  }
}
.coreal-hero-content-2 .title span {
  color: #3257a8;
}
.coreal-hero-content-2 ul {
  margin: 0;
  padding: 25px 0 0;
  list-style-type: none;
}
.coreal-hero-content-2 ul li {
  margin-right: 27px;
  display: inline-block;
}
@media (max-width: 767px) {
  .coreal-hero-content-2 ul li {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-hero-content-2 ul li {
    margin-bottom: 0px;
  }
}

.coreal-hero-thumb-2 {
  text-align: right;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-hero-thumb-2 {
    display: none;
  }
}
@media (max-width: 767px) {
  .coreal-hero-thumb-2 {
    display: none;
  }
}

.hero-fun-facts {
  margin-top: -11px;
}
@media (max-width: 767px) {
  .hero-fun-facts {
    display: none;
  }
}
.hero-fun-facts .hero-title .title {
  color: #5f5f5f;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  margin-bottom: 0;
}
.hero-fun-facts .hero-title .title::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 92%;
  background: #1d1d1d;
}

.hero-fun-facts-box .hero-fun-facts-item {
  margin-top: 35px;
  padding-bottom: 55px;
}
.hero-fun-facts-box .hero-fun-facts-item .title {
  font-size: 60px;
  color: #fff;
  margin-bottom: -6px;
  line-height: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-fun-facts-box .hero-fun-facts-item .title {
    font-size: 45px;
  }
}
.hero-fun-facts-box .hero-fun-facts-item span {
  color: #999;
}

.coreal-hero-3-area {
  height: 700px;
  background-image: url(../images/banner-thumb.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}
.coreal-hero-3-area .shape {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: -1;
  width: 1280px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-hero-3-area .shape {
    width: 100%;
  }
}

.coreal-hero-3-content {
  text-align: center;
  padding-top: 84px;
}
.coreal-hero-3-content .title {
  color: #fff;
  font-size: 80px;
  line-height: 90px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-hero-3-content .title {
    font-size: 60px;
    line-height: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-hero-3-content .title {
    font-size: 64px;
    line-height: 74px;
  }
}
@media (max-width: 767px) {
  .coreal-hero-3-content .title {
    font-size: 34px;
    line-height: 44px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-hero-3-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}
.coreal-hero-3-content .coreal-hero-form {
  text-align: center;
  margin-top: 60px;
}
.coreal-hero-3-content .coreal-hero-form .input-box {
  width: 650px;
  display: inline-block;
  position: relative;
}
@media (max-width: 767px) {
  .coreal-hero-3-content .coreal-hero-form .input-box {
    width: 100%;
  }
}
.coreal-hero-3-content .coreal-hero-form .input-box input {
  height: 70px;
  width: 100%;
  border: 0;
  border-radius: 15px;
  padding-left: 40px;
}
.coreal-hero-3-content .coreal-hero-form .input-box button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 50px;
  border: 0;
  background: #3257a8;
  border-radius: 15px;
  color: #fff;
  padding: 0 40px;
}
@media (max-width: 767px) {
  .coreal-hero-3-content .coreal-hero-form .input-box button {
    position: static;
    transform: translateY(0);
    margin-top: 20px;
  }
}
.coreal-hero-3-content .coreal-hero-form .input-box button i {
  padding-right: 6px;
}
.coreal-hero-3-content .coreal-hero-form .input-box .coreal-select-item {
  position: absolute;
  right: 210px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .coreal-hero-3-content .coreal-hero-form .input-box .coreal-select-item {
    display: none;
  }
}
.coreal-hero-3-content
  .coreal-hero-form
  .input-box
  .coreal-select-item
  .nice-select {
  border: 0;
}
.coreal-hero-3-content
  .coreal-hero-form
  .input-box
  .coreal-select-item
  .nice-select::after {
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
  right: auto;
  left: 0;
}

/*===========================
    7. COREAL CATEGORY css 
===========================*/
.coreal-category-area {
  padding: 90px 0 152px;
  background: #f8f8f8;
}

.coreal-category-box {
  margin-left: -15px;
  margin-right: -15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-category-box {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .coreal-category-box {
    flex-wrap: wrap;
  }
}
.coreal-category-box .coreal-category-item {
  background: #fff;
  text-align: center;
  width: 100%;
  margin: 0 15px;
  padding-top: 50px;
  padding-bottom: 45px;
  border: 2px solid #e8e8e8;
  border-radius: 15px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  margin-top: 30px;
  position: relative;
  transition: all linear 0.3s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-category-box .coreal-category-item {
    width: 45%;
  }
}
@media (max-width: 767px) {
  .coreal-category-box .coreal-category-item {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-category-box .coreal-category-item {
    width: 44%;
  }
}
.coreal-category-box .coreal-category-item .title {
  font-size: 18px;
  margin-top: 22px;
  margin-bottom: 3px;
}
.coreal-category-box .coreal-category-item .title a {
  color: #111111;
}
.coreal-category-box .coreal-category-item .title a:hover {
  color: #3257a8;
}
.coreal-category-box .coreal-category-item > span {
  font-size: 14px;
}
.coreal-category-box .coreal-category-item .rebon {
  position: absolute;
  top: -10px;
  right: 20px;
  background: #3257a8;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  padding: 0 10px;
  border-radius: 30px;
}
.coreal-category-box .coreal-category-item:hover {
  transform: translateY(-10px);
}

.coreal-loan-contact-item {
  margin-top: -52px;
}
.coreal-loan-contact-item .content {
  border: 2px solid #e8e8e8;
  align-items: center;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  background: #fff;
}
.coreal-loan-contact-item .content > span {
  font-size: 18px;
  padding-left: 30px;
  display: inline-block;
}
@media (max-width: 767px) {
  .coreal-loan-contact-item .content > span {
    padding-left: 0;
    padding-bottom: 30px;
  }
}
.coreal-loan-contact-item .content > span span {
  font-size: 24px;
  color: #111111;
  font-weight: 700;
}
.coreal-loan-contact-item .content a {
  background: #3257a8;
  color: #fff;
  display: inline-block;
  line-height: 50px;
  border-radius: 15px;
  padding: 0 35px;
}

/*===========================
    8. COREAL FUN FACTS css 
===========================*/
.coreal-fun-facts-area {
  background: #f8f8f8;
  padding-top: 90px;
  padding-bottom: 95px;
}

.coreal-fun-facts-content {
  padding-right: 50px;
  padding-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-fun-facts-content {
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-fun-facts-content {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .coreal-fun-facts-content {
    padding-right: 0;
  }
}
.coreal-fun-facts-content span {
  font-size: 14px;
  color: #3257a8;
  font-weight: 700;
}
.coreal-fun-facts-content .title {
  font-size: 48px;
  line-height: 58px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-fun-facts-content .title {
    font-size: 40px;
    line-height: 46px;
  }
}
@media (max-width: 767px) {
  .coreal-fun-facts-content .title {
    font-size: 27px;
    line-height: 32px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-fun-facts-content .title {
    font-size: 38px;
    line-height: 44px;
  }
}

.coreal-fun-facts-subscribe {
  padding-right: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-fun-facts-subscribe {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .coreal-fun-facts-subscribe {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .coreal-fun-facts-subscribe .input-box {
    margin-bottom: 20px;
  }
}
.coreal-fun-facts-subscribe .input-box input {
  width: 100%;
  border: 2px solid #eaeaea;
  padding-left: 30px;
  height: 60px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
}
.coreal-fun-facts-subscribe .input-box button {
  background: #3257a8;
  color: #fff;
  line-height: 60px;
  border: 2px solid #3257a8;
  padding: 0 70px;
  border-radius: 15px;
  margin-top: 22px;
  font-size: 16px;
  font-weight: 700;
}

.coreal-fun-facts-text {
  font-size: 18px;
  line-height: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-fun-facts-text {
    margin-top: 50px;
  }
}
.coreal-fun-facts-text .coreal-fun-facts-count {
  margin-top: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-fun-facts-text .coreal-fun-facts-count {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .coreal-fun-facts-text .coreal-fun-facts-count {
    text-align: center;
  }
}
.coreal-fun-facts-text .coreal-fun-facts-count .title {
  font-size: 60px;
  margin-bottom: 0;
  line-height: 57px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-fun-facts-text .coreal-fun-facts-count .title {
    font-size: 40px;
  }
}

/*===========================
    9. COREAL FEATURES css 
===========================*/
.coreal-fetaures-area {
  padding: 90px 0 100px;
}

.coreal-fetaures-item {
  text-align: center;
  border: 2px solid #eaeaea;
  border-radius: 15px;
  padding: 48px 30px 25px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-fetaures-item {
    padding: 40px 10px 25px;
  }
}
@media (max-width: 767px) {
  .coreal-fetaures-item {
    padding: 40px 10px 25px;
  }
}
.coreal-fetaures-item .title {
  font-size: 24px;
  margin-bottom: 10px;
  padding-top: 21px;
}
.coreal-fetaures-item:hover {
  box-shadow: none;
}

.coreal-service-item {
  text-align: center;
  border: 2px solid #eaeaea;
  border-radius: 15px;
  padding: 65px 30px 50px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-service-item {
    padding: 40px 10px 25px;
  }
}
@media (max-width: 767px) {
  .coreal-service-item {
    padding: 40px 10px 25px;
  }
}
.coreal-service-item .title {
  font-size: 18px;
  margin-bottom: 10px;
  padding-top: 21px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-service-item .title {
    font-size: 16px;
  }
}
.coreal-service-item p {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.coreal-service-item img {
  margin-top: 8px;
}
.coreal-service-item:hover {
  box-shadow: none;
  background: #3257a8;
  border-color: #3257a8;
}
.coreal-service-item:hover span {
  color: #3257a8;
  text-shadow: 2px 2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff,
    -2px -2px 0 #ffffff, 2px 0px 0 #ffffff, 0px 2px 0 #ffffff,
    -2px 0px 0 #ffffff, 0px -2px 0 #ffffff;
}
.coreal-service-item:hover .title {
  color: #fff;
}
.coreal-service-item:hover p {
  color: rgba(255, 255, 255, 0.6);
}
.coreal-service-item span {
  font-size: 80px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 2px 2px 0 #ededed, 2px -2px 0 #ededed, -2px 2px 0 #ededed,
    -2px -2px 0 #ededed, 2px 0px 0 #ededed, 0px 2px 0 #ededed,
    -2px 0px 0 #ededed, 0px -2px 0 #ededed;
}

.coreal-features-gallery-box-3 {
  border-radius: 15px;
}
.coreal-features-gallery-box-3 > img {
  width: 100%;
  border-radius: 15px;
}

.coreal-features-gallery-item-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: -60px;
  position: relative;
  z-index: 9;
  padding-left: 40px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0 15px 15px 15px;
  padding: 0px 0px 0px 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-features-gallery-item-3 {
    padding-left: 10px;
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-features-gallery-item-3 {
    padding-left: 10px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .coreal-features-gallery-item-3 {
    padding-left: 10px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0;
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-features-gallery-item-3 {
    display: flex;
  }
}
.coreal-features-gallery-item-3 .sub-bar {
  position: absolute;
  bottom: 100%;
  left: 0;
  background: #3257a8;
  z-index: 10;
}
.coreal-features-gallery-item-3 .sub-bar::before {
  position: absolute;
  content: "";
  right: -6px;
  top: 0;
  width: 58%;
  height: 100%;
  background: #3257a8;
  z-index: -1;
  transform: skew(20deg);
}
.coreal-features-gallery-item-3 .sub-bar span {
  display: inline-block;
  line-height: 20px;
  padding: 0 17px 0 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  font-size: 12px;
  position: relative;
}
@media (max-width: 767px) {
  .coreal-features-gallery-item-3 .coreal-features-gallery-content {
    padding: 10px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-features-gallery-item-3 .coreal-features-gallery-content {
    padding: 0px 0;
  }
}
.coreal-features-gallery-item-3 .coreal-features-gallery-content .title {
  font-size: 24px;
  margin-bottom: 0;
}
.coreal-features-gallery-item-3 .coreal-features-gallery-content p {
  font-size: 14px;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-features-gallery-item-3 .coreal-features-gallery-icon {
    display: none;
  }
}
@media (max-width: 767px) {
  .coreal-features-gallery-item-3 .coreal-features-gallery-icon {
    display: none;
  }
}
.coreal-features-gallery-item-3 .coreal-features-gallery-icon ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-features-gallery-item-3 .coreal-features-gallery-icon ul li {
  text-align: center;
  display: inline-block;
  padding-left: 25px;
  padding-right: 25px;
  border-left: 1px solid #e3e3e3;
}
.coreal-features-gallery-item-3 .coreal-features-gallery-icon ul li span {
  display: block;
  font-size: 14px;
  color: #777777;
  padding-top: 5px;
}
.coreal-features-gallery-item-3 .coreal-features-gallery-icon ul li:last-child {
  padding-right: 0;
}
.coreal-features-gallery-item-3
  .coreal-features-gallery-icon
  ul
  li:first-child {
  padding-left: 0;
  border-left: 0;
}
.coreal-features-gallery-item-3 .coreal-features-gallery-price {
  background: #3257a8;
  height: 110px;
  border-radius: 0 15px 15px 0;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-features-gallery-item-3 .coreal-features-gallery-price {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 767px) {
  .coreal-features-gallery-item-3 .coreal-features-gallery-price {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-features-gallery-item-3 .coreal-features-gallery-price {
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
  }
}
.coreal-features-gallery-item-3 .coreal-features-gallery-price span {
  font-size: 14px;
  color: #fff;
  margin-bottom: -7px;
  display: block;
}
.coreal-features-gallery-item-3 .coreal-features-gallery-price .title {
  font-size: 24px;
  color: #fff;
  white-space: nowrap;
}
.coreal-features-gallery-item-3 .coreal-features-gallery-price .title sub {
  font-size: 14px;
  font-weight: 700;
  margin-left: -5px;
}

.coreal-featured-gallery-slider-active .slick-arrow {
  position: absolute;
  top: 47%;
  transform: translateY(-50%);
  z-index: 9;
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  background: #fff;
  border-radius: 8px;
  border: 2px solid #f0f0f0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  cursor: pointer;
  font-size: 20px;
  color: #111111;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-featured-gallery-slider-active .slick-arrow {
    height: 50px;
    width: 50px;
    line-height: 46px;
    font-size: 16px;
  }
}
.coreal-featured-gallery-slider-active .slick-arrow:hover {
  background: #3257a8;
  border-color: #3257a8;
  color: #fff;
}
.coreal-featured-gallery-slider-active .slick-arrow.prev {
  left: 90px;
}
.coreal-featured-gallery-slider-active .slick-arrow.next {
  right: 90px;
}
.coreal-featured-gallery-slider-active:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}
.coreal-featured-gallery-slider-active:hover .slick-arrow.prev {
  left: 30px;
}
.coreal-featured-gallery-slider-active:hover .slick-arrow.next {
  right: 30px;
}

.coreal-featurd-3-item {
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-featurd-3-item.item {
    display: none;
  }
}
.coreal-featurd-3-item img {
  width: 100%;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.coreal-featurd-3-item .coreal-featurd-3-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000;
  background-color: rgba(17, 17, 17, 0.8);
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-featurd-3-item .coreal-featurd-3-overlay {
    padding: 10px 10px;
  }
}
.coreal-featurd-3-item .coreal-featurd-3-overlay ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-featurd-3-item .coreal-featurd-3-overlay ul li {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  align-items: center;
}
.coreal-featurd-3-item .coreal-featurd-3-overlay ul li p {
  margin-bottom: 0;
  font-weight: 700;
  color: #fff;
}
.coreal-featurd-3-item .coreal-featurd-3-overlay ul li span {
  font-size: 12px;
  color: #fff;
}
.coreal-featurd-3-item .coreal-featurd-3-overlay .coreal-featurd-3-content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 22px 30px;
  background: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-featurd-3-item .coreal-featurd-3-overlay .coreal-featurd-3-content {
    padding: 2px 10px;
  }
}
.coreal-featurd-3-item
  .coreal-featurd-3-overlay
  .coreal-featurd-3-content
  .title {
  font-size: 18px;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-featurd-3-item
    .coreal-featurd-3-overlay
    .coreal-featurd-3-content
    .title {
    font-size: 16px;
  }
}
.coreal-featurd-3-item
  .coreal-featurd-3-overlay
  .coreal-featurd-3-content
  .title
  a {
  color: #111111;
}
.coreal-featurd-3-item
  .coreal-featurd-3-overlay
  .coreal-featurd-3-content
  .title
  a:hover {
  color: #3257a8;
}
.coreal-featurd-3-item
  .coreal-featurd-3-overlay
  .coreal-featurd-3-content
  span {
  font-size: 14px;
}
.coreal-featurd-3-item
  .coreal-featurd-3-overlay
  .coreal-featurd-3-content
  span
  i {
  color: #3257a8;
  padding-right: 6px;
}
.coreal-featurd-3-item:hover {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.coreal-featurd-3-item:hover .coreal-featurd-3-overlay {
  opacity: 1;
  visibility: visible;
}
.coreal-featurd-3-item:hover > img {
  transform: scale(1.2);
}

/*===========================
    10. COREAL EXPLORE css 
===========================*/
.coreal-explore-area {
  background: #f8f8f8;
  padding-top: 92px;
  padding-bottom: 100px;
  overflow: hidden;
}
.coreal-explore-area .row.mr-15 {
  margin-right: -30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-explore-area .row.mr-15 {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .coreal-explore-area .row.mr-15 {
    margin-right: 0;
  }
}

.coreal-explore-top-explore {
  text-align: center;
  padding-bottom: 30px;
}
.coreal-explore-top-explore span {
  font-size: 20px;
  font-weight: 700;
  color: #111111;
  display: inline-block;
  margin-bottom: 40px;
}
.coreal-explore-top-explore .input-box {
  display: inline-block;
  position: relative;
}
@media (max-width: 767px) {
  .coreal-explore-top-explore .input-box {
    display: block;
    margin-left: 30px;
    margin-right: 30px;
  }
}
.coreal-explore-top-explore .input-box input {
  width: 600px;
  height: 80px;
  padding-left: 65px;
  border: 2px solid #eaeaea;
  border-radius: 15px;
  background: #fff;
  color: #999;
}
@media (max-width: 767px) {
  .coreal-explore-top-explore .input-box input {
    width: 100%;
  }
}
.coreal-explore-top-explore .input-box input::placeholder {
  opacity: 1;
  color: #999;
}
.coreal-explore-top-explore .input-box button {
  position: absolute;
  left: 38px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  border: 0;
  background: transparent;
  color: #999;
}

.coreal-explore-item {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.coreal-explore-item img {
  width: 100%;
  transition: all linear 0.3s;
}
.coreal-explore-item .coreal-explore-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.70196) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 40px;
  padding-bottom: 22px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-explore-item .coreal-explore-overlay {
    padding-left: 20px;
    padding-bottom: 2px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-explore-item .coreal-explore-overlay {
    padding-left: 20px;
    padding-bottom: 2px;
  }
}
@media (max-width: 767px) {
  .coreal-explore-item .coreal-explore-overlay {
    padding-left: 20px;
    padding-bottom: 2px;
  }
}
.coreal-explore-item .coreal-explore-overlay span {
  color: #fff;
}
.coreal-explore-item .coreal-explore-overlay .title {
  color: #fff;
  font-size: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-explore-item .coreal-explore-overlay .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-explore-item .coreal-explore-overlay .title {
    font-size: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-explore-item .coreal-explore-overlay .title {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .coreal-explore-item .coreal-explore-overlay .title {
    font-size: 24px;
  }
}
.coreal-explore-item .coreal-explore-overlay .title a {
  color: #fff;
}
.coreal-explore-item .coreal-explore-overlay .title a:hover {
  color: #3257a8;
}
@media (max-width: 767px) {
  .coreal-explore-item.item {
    display: none;
  }
}
.coreal-explore-item:hover img {
  transform: scale(1.2);
}

.coreal-download-area {
  padding-top: 100px;
}

.coreal-download-content {
  font-size: 18px;
  line-height: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-download-content {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .coreal-download-content {
    margin-bottom: 50px;
  }
}
.coreal-download-content span {
  font-size: 14px;
  font-weight: 700;
  color: #3257a8;
}
.coreal-download-content .title {
  font-size: 48px;
  padding-top: 5px;
  margin-bottom: 28px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-download-content .title {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  .coreal-download-content .title {
    font-size: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-download-content .title {
    font-size: 36px;
  }
}
.coreal-download-content ul {
  margin: 0;
  padding: 27px 0 0;
  list-style-type: none;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-download-content ul {
    padding: 0;
  }
}
.coreal-download-content ul li {
  display: inline-block;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .coreal-download-content ul li {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-download-content ul li {
    margin-bottom: 0px;
  }
}
.coreal-download-content ul li a {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}

/*===========================
    11. COREAL TESTIMONIAL css 
===========================*/
.coreal-testimonial-area {
  background: linear-gradient(0deg, white 0%, #f7f7f7 100%);
  padding-top: 90px;
  padding-bottom: 100px;
  overflow: hidden;
}

.coreal-testimonial-item {
  background: #fff;
  padding: 50px;
  border: 2px solid #eaeaea;
  position: relative;
  margin-top: 20px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  z-index: 10;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-testimonial-item {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .coreal-testimonial-item {
    padding: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-testimonial-item {
    padding: 50px;
  }
}
.coreal-testimonial-item::before {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  width: 175px;
  height: 155px;
  background-image: url(../images/quote-icon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  z-index: -1;
}
.coreal-testimonial-item p {
  font-size: 20px;
  line-height: 34px;
}
.coreal-testimonial-item .meta-author {
  align-items: center;
  margin-top: 23px;
}
.coreal-testimonial-item .meta-author .thumb {
  border-radius: 50%;
  overflow: hidden;
  height: 60px;
  max-width: 60px;
  min-width: 60px;
  margin-right: 15px;
}
.coreal-testimonial-item .meta-author .thumb img {
  width: 100%;
}
.coreal-testimonial-item .meta-author .text .title {
  margin-bottom: -3px;
  font-size: 18px;
}
.coreal-testimonial-item .meta-author .text span {
  font-size: 14px;
}

.coreal-sponser-area {
  overflow: hidden;
}
.coreal-sponser-area .coreal-sponser-box {
  border-top: 1px solid #dadada;
  padding-top: 100px;
}
.coreal-sponser-area .coreal-sponser-box .item-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.coreal-sponser-area .coreal-sponser-box .item-list .item {
  width: 20%;
  margin-bottom: 58px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-sponser-area .coreal-sponser-box .item-list .item {
    width: 25%;
  }
}
@media (max-width: 767px) {
  .coreal-sponser-area .coreal-sponser-box .item-list .item {
    width: 50%;
    padding: 0 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-sponser-area .coreal-sponser-box .item-list .item {
    width: 33%;
    padding: 0;
  }
}

/*===========================
    12. COREAL LISTING TAB css 
===========================*/
.coreal-listing-tab-area.coreal-listing-tab-area-3 {
  background: #f8f8f8;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listing-tab-area.coreal-listing-tab-area-3
    .coreal-listing-tab-btn
    ul.nav-pills
    li {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .coreal-listing-tab-area.coreal-listing-tab-area-3
    .coreal-listing-tab-btn
    ul.nav-pills
    li {
    margin-bottom: 15px;
  }
}
.coreal-listing-tab-area.coreal-listing-tab-area-3
  .coreal-listing-tab-btn
  ul.nav-pills
  li
  button {
  margin: 0 8px;
}
.coreal-listing-tab-area.coreal-listing-tab-area-3
  .coreal-listing-tab-btn
  .coreal-listing-tab-link
  a {
  background: #ededed;
  color: #777777;
}

.coreal-listing-tab-btn ul.nav-pills li button {
  padding: 0;
  background: #fff;
  border: 2px solid #ededed;
  line-height: 60px;
  padding: 0 38px;
  border-radius: 15px !important;
  margin-right: 16px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
  color: #777777;
  text-transform: capitalize;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-listing-tab-btn ul.nav-pills li button {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listing-tab-btn ul.nav-pills li button {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .coreal-listing-tab-btn ul.nav-pills li button {
    margin-bottom: 15px;
    padding: 0 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-listing-tab-btn ul.nav-pills li button {
    margin-bottom: 15px;
    padding: 0 10px;
  }
}
.coreal-listing-tab-btn ul.nav-pills li button.active {
  background-color: #3257a8;
  border-color: #3257a8;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-listing-tab-btn .coreal-listing-tab-link {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listing-tab-btn .coreal-listing-tab-link {
    display: none;
  }
}
@media (max-width: 767px) {
  .coreal-listing-tab-btn .coreal-listing-tab-link {
    display: none;
  }
}
.coreal-listing-tab-btn .coreal-listing-tab-link a {
  background: #f8f8f8;
  color: #777777;
  border-color: #f8f8f8;
}

/*===========================
    13. COREAL TRACK css 
===========================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-track-area {
    display: none;
  }
}
@media (max-width: 767px) {
  .coreal-track-area {
    display: none;
  }
}
.coreal-track-area .coreal-track-box {
  position: relative;
}

.coreal-track-location {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.coreal-track-location .item {
  height: 50px;
  width: 50px;
  border: 3px solid #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  cursor: pointer;
}
.coreal-track-location .item img {
  border-radius: 12px;
}
.coreal-track-location .item .coreal-track-user-info {
  position: absolute;
  bottom: 100%;
  width: 245px;
  height: 130px;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 22px 30px;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.8s ease-out 0s;
  -moz-transition: all 0.8s ease-out 0s;
  -ms-transition: all 0.8s ease-out 0s;
  -o-transition: all 0.8s ease-out 0s;
  transition: all 0.8s ease-out 0s;
}
.coreal-track-location .item .coreal-track-user-info::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid #fff;
}
.coreal-track-location .item .coreal-track-user-info .title {
  font-size: 20px;
  margin-bottom: 10px;
}
.coreal-track-location .item .coreal-track-user-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-track-location .item .coreal-track-user-info ul li {
  font-size: 14px;
}
.coreal-track-location .item .coreal-track-user-info ul li i {
  color: #3257a8;
  padding-right: 6px;
}
.coreal-track-location .item:hover .coreal-track-user-info {
  bottom: 150%;
  opacity: 1;
  visibility: visible;
}
.coreal-track-location .item-1 {
  position: absolute;
  left: 0;
  top: 70px;
}
.coreal-track-location .item-2 {
  position: absolute;
  left: 260px;
  top: 160px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-track-location .item-2 {
    left: 150px;
  }
}
.coreal-track-location .item-3 {
  position: absolute;
  left: 360px;
  bottom: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-track-location .item-3 {
    left: 300px;
  }
}
.coreal-track-location .item-4 {
  position: absolute;
  right: 140px;
  top: 70px;
}
.coreal-track-location .item-5 {
  position: absolute;
  right: 470px;
  top: 210px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-track-location .item-5 {
    right: 0;
  }
}
.coreal-track-location .item-6 {
  position: absolute;
  right: 220px;
  bottom: 160px;
}

.coreal-cta-area {
  padding-bottom: 120px;
  overflow: hidden;
}
.coreal-cta-area .coreal-cta-box {
  padding: 45px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  position: relative;
}
@media (max-width: 767px) {
  .coreal-cta-area .coreal-cta-box {
    padding: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-cta-area .coreal-cta-box {
    padding: 30px;
  }
}
.coreal-cta-area .coreal-cta-box::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  height: 20px;
  width: 95%;
  background: #edf1f9;
}

.coreal-cta-info {
  padding-left: 70px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-cta-info {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .coreal-cta-info {
    margin-bottom: 30px;
  }
}
.coreal-cta-info .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  border: 2px solid #f0f0f0;
  text-align: center;
  line-height: 50px;
  border-radius: 12px;
  color: #3257a8;
  font-size: 18px;
}
.coreal-cta-info .title {
  font-size: 14px;
  margin-bottom: 5px;
}
.coreal-cta-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-cta-info ul li {
  font-size: 18px;
}
.coreal-cta-info .coreal-cta-support-icon {
  position: absolute;
  right: 130px;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-cta-info .coreal-cta-support-icon {
    right: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-cta-info .coreal-cta-support-icon {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-cta-info .coreal-cta-support-icon {
    display: block;
  }
}
.coreal-cta-info .coreal-cta-support-icon a {
  display: inline-block;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  background: #3257a8;
  color: #fff;
  position: relative;
}
.coreal-cta-info .coreal-cta-support-icon a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  animation: popup 1.5s linear infinite;
}
.coreal-cta-info .coreal-cta-support-icon a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  animation: popup 3s linear infinite;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.coreal-cta-newsletter {
  padding-left: 70px;
  position: relative;
}
.coreal-cta-newsletter .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  border: 2px solid #f0f0f0;
  text-align: center;
  line-height: 50px;
  border-radius: 12px;
  color: #3257a8;
  font-size: 18px;
}
.coreal-cta-newsletter span span {
  font-weight: 700;
  color: #111111;
}
.coreal-cta-newsletter .input-box {
  position: relative;
}
.coreal-cta-newsletter .input-box button {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #3257a8;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  border: 0;
  color: #fff;
  font-size: 18px;
}
.coreal-cta-newsletter .input-box input {
  width: 100%;
  border: 0;
  border-bottom: 2px solid #eeeeee;
  height: 60px;
  font-size: 14px;
  color: #c9c9c9;
}
.coreal-cta-newsletter .input-box input::placeholder {
  opacity: 1;
  color: #c9c9c9;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-faq-area .coreal-faq-content {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-faq-area .coreal-faq-content {
    padding-left: 0;
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-faq-area .coreal-faq-content {
    padding-left: 0;
    margin-top: 30px;
  }
}

/*===========================
    14. COREAL PROPERTY css 
===========================*/
.coreal-property-area {
  background: #111111;
  padding-top: 100px;
  padding-bottom: 405px;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-property-area {
    padding-bottom: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-property-area {
    padding-bottom: 230px;
  }
}
@media (max-width: 767px) {
  .coreal-property-area {
    margin-bottom: 70px;
    padding-bottom: 70px;
  }
}
.coreal-property-area::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/property-line.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.coreal-property-area .section-title {
  padding-right: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-property-area .section-title {
    padding-right: 0;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .coreal-property-area .section-title {
    padding-right: 0;
    margin-bottom: 50px;
  }
}
.coreal-property-area .section-title span {
  color: #999;
}
.coreal-property-area .section-title .title {
  color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-property-area .section-title .title {
    font-size: 36px;
  }
}

.coreal-property-btn {
  background: #fff;
  border-radius: 10px;
  padding: 45px 0 40px;
  margin-left: 15px;
  margin-right: 15px;
}
.coreal-property-btn a {
  display: block;
  font-weight: 700;
  color: #111111;
  padding-top: 10px;
}
.coreal-property-btn img {
  display: inline-block;
}

.coreal-property-slider-active {
  width: 100vw;
}

.coreal-property-thumb {
  position: relative;
}
.coreal-property-thumb .play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.coreal-property-thumb .play a {
  display: inline-block;
  height: 120px;
  width: 120px;
  text-align: center;
  line-height: 120px;
  border-radius: 50%;
  background: #fff;
  font-size: 18px;
  color: #111111;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-property-thumb .play a {
    height: 70px;
    width: 70px;
    font-size: 14px;
    line-height: 70px;
  }
}
.coreal-property-thumb .play a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  animation: popup 1.5s linear infinite;
  border-radius: 50%;
}
.coreal-property-thumb .play a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  animation: popup 2.5s linear infinite;
  border-radius: 50%;
}
.coreal-property-thumb img {
  width: 100%;
}
.coreal-property-thumb.item-1 {
  margin-right: -14px;
}
@media (max-width: 767px) {
  .coreal-property-thumb.item-1 {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.coreal-property-thumb.item-2 {
  margin-left: 13px;
}
@media (max-width: 767px) {
  .coreal-property-thumb.item-2 {
    margin-left: 0;
  }
}

.coreal-property-thumb-area {
  margin-top: -305px;
  padding-bottom: 90px;
  position: relative;
  z-index: 11;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-property-thumb-area {
    margin-top: -200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-property-thumb-area {
    margin-top: -160px;
  }
}
@media (max-width: 767px) {
  .coreal-property-thumb-area {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-property-thumb-area {
    display: block;
  }
}

.coreal-listed-porperties-area {
  padding-bottom: 100px;
}

.coreal-listed-porperties-topbar .coreal-page-listed-result {
  width: 50%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listed-porperties-topbar .coreal-page-listed-result {
    width: 32%;
  }
}
.coreal-listed-porperties-topbar .coreal-page-listed-result p {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .coreal-listed-porperties-topbar .coreal-page-listed-result p {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .coreal-listed-porperties-topbar .coreal-page-listed-result {
    width: 100%;
  }
}
.coreal-listed-porperties-topbar .coreal-page-listed-result p span {
  font-weight: 700;
  color: #111111;
}
.coreal-listed-porperties-topbar .coreal-listed-porperties-btns {
  width: 50%;
  text-align: right;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listed-porperties-topbar .coreal-listed-porperties-btns {
    width: 68%;
  }
}
@media (max-width: 767px) {
  .coreal-listed-porperties-topbar .coreal-listed-porperties-btns {
    width: 100%;
    text-align: left;
  }
}
.coreal-listed-porperties-topbar .coreal-listed-porperties-btns ul {
  display: inline-block;
}
.coreal-listed-porperties-topbar .coreal-listed-porperties-btns ul li {
  display: inline-block;
}
.coreal-listed-porperties-topbar .coreal-listed-porperties-btns ul li button {
  border: 2px solid #ededed;
  background: transparent;
  padding: 0;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 14px;
  color: #777777;
  margin-left: 17px;
}
.coreal-listed-porperties-topbar
  .coreal-listed-porperties-btns
  ul
  li
  button.active {
  background-color: #3257a8;
  border-color: #3257a8;
  color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
}
.coreal-listed-porperties-topbar
  .coreal-listed-porperties-btns
  .coreal-select-item {
  display: inline-block;
}
.coreal-listed-porperties-topbar
  .coreal-listed-porperties-btns
  .coreal-select-item
  .nice-select {
  float: none;
  width: auto;
  padding: 0 35px;
  line-height: 60px;
  height: 60px;
  border: 2px solid #ededed;
  border-radius: 14px;
  color: #777777;
  font-weight: 700;
}
.coreal-listed-porperties-topbar
  .coreal-listed-porperties-btns
  .coreal-select-item
  .nice-select
  .list
  li {
  display: block;
}
.coreal-listed-porperties-topbar
  .coreal-listed-porperties-btns
  .coreal-select-item
  .nice-select::after {
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
  top: 56%;
  right: 22px;
}

.coreal-listing-properties-pagination nav ul li {
  margin: 0 7px;
}
.coreal-listing-properties-pagination nav ul li a {
  height: 50px;
  width: 50px;
  padding: 0;
  text-align: center;
  line-height: 50px;
  border-radius: 15px !important;
  border: 2px solid #ededed;
  color: #777777;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
}
.coreal-listing-properties-pagination nav ul li a:hover {
  background: #3257a8;
  border-color: #3257a8;
  color: #fff;
}

.coreal-map-listing-topbar-area {
  position: relative;
  z-index: 11;
}

/*===========================
    15. COREAL DESTINATIONS css 
===========================*/
.coreal-destinations-area {
  overflow: hidden;
  padding-bottom: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-destinations-area {
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .coreal-destinations-area {
    padding-bottom: 0;
  }
}
.coreal-destinations-area .coreal-destinations-search-box .input-box {
  position: relative;
}
.coreal-destinations-area .coreal-destinations-search-box .input-box input {
  width: 100%;
  height: 80px;
  border: 2px solid #eaeaea;
  border-radius: 15px;
  padding-left: 65px;
  color: #999;
}
.coreal-destinations-area
  .coreal-destinations-search-box
  .input-box
  input::placeholder {
  opacity: 1;
  color: #999;
}
.coreal-destinations-area .coreal-destinations-search-box .input-box button {
  position: absolute;
  left: 35px;
  background: transparent;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  border: 0;
  color: #999;
}

.coreal-destinations-location-item {
  position: relative;
  z-index: 10;
  border-radius: 14px;
  overflow: hidden;
  margin-left: 15px;
  margin-right: 15px;
}
.coreal-destinations-location-item img {
  width: 100%;
  -webkit-transition: all 0.6s ease-out 0s;
  -moz-transition: all 0.6s ease-out 0s;
  -ms-transition: all 0.6s ease-out 0s;
  -o-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
}
.coreal-destinations-location-item .coreal-destinations-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.70196) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  align-items: flex-end;
  padding-left: 40px;
  padding-bottom: 22px;
}
.coreal-destinations-location-item .coreal-destinations-overlay .content span {
  color: #fff;
}
.coreal-destinations-location-item
  .coreal-destinations-overlay
  .content
  .title {
  font-size: 50px;
  color: #fff;
}
.coreal-destinations-location-item
  .coreal-destinations-overlay
  .content
  .title
  a {
  color: #fff;
}
.coreal-destinations-location-item
  .coreal-destinations-overlay
  .content
  .title
  a:hover {
  color: #3257a8;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-destinations-location-item
    .coreal-destinations-overlay
    .content
    .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-destinations-location-item
    .coreal-destinations-overlay
    .content
    .title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-destinations-location-item
    .coreal-destinations-overlay
    .content
    .title {
    font-size: 32px;
  }
}
.coreal-destinations-location-item:hover img {
  transform: scale(1.1);
}

/*===========================
    15. COREAL SPONSOR css 
===========================*/
.coreal-sponsor-area {
  background: #f8f8f8;
  padding-top: 90px;
  padding-bottom: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-sponsor-area {
    display: none;
  }
}
@media (max-width: 767px) {
  .coreal-sponsor-area {
    display: none;
  }
}
.coreal-sponsor-area .coreal-sponser-box .item-list {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.coreal-sponsor-area .coreal-sponser-box .item-list .item {
  width: 20%;
  padding: 50px 0;
  border: 1px solid #e3e3e3;
}
.coreal-sponsor-area .coreal-sponser-box .item-list .item img {
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.coreal-sponsor-area .coreal-sponser-box .item-list .item:first-child {
  border-left: 1px solid #e3e3e3;
}
.coreal-sponsor-area .coreal-sponser-box .item-list .item.first {
  border-right: 0;
}
.coreal-sponsor-area .coreal-sponser-box .item-list .item.second {
  border-right: 0;
}
.coreal-sponsor-area .coreal-sponser-box .item-list .item:hover img {
  transform: scale(1.1);
}
.coreal-sponsor-area .coreal-sponser-box .item-list.item-1 .item {
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
}
.coreal-sponsor-area .coreal-sponser-box .item-list.item-2 .item {
  border-left: 0;
  border-bottom: 0;
}
.coreal-sponsor-area.coreal-sponsor-area-3 {
  background: #111111;
}
.coreal-sponsor-area.coreal-sponsor-area-3 .section-title span {
  color: #999999;
}
.coreal-sponsor-area.coreal-sponsor-area-3 .section-title .title {
  color: #fff;
}
.coreal-sponsor-area.coreal-sponsor-area-3
  .coreal-sponser-box
  .item-list
  .item {
  border-color: #1f1f1f;
}

/*===========================
    16. COREAL BLOG css 
===========================*/
.coreal-single-blog {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  overflow: hidden;
}
.coreal-single-blog .thumb {
  overflow: hidden;
}
.coreal-single-blog .thumb img {
  width: 100%;
  transition: all linear 0.5s;
}
.coreal-single-blog .content {
  padding: 40px 40px 20px;
  border: 2px solid #e9e9e9;
  border-top: 0;
  border-radius: 0 0 15px 15px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-single-blog .content {
    padding: 30px 30px 20px;
  }
}
@media (max-width: 767px) {
  .coreal-single-blog .content {
    padding: 40px 20px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-single-blog .content {
    padding: 40px 40px 20px;
  }
}
.coreal-single-blog .content .meta-items {
  display: flex;
}
.coreal-single-blog .content .meta-items .meta-categores a {
  background: #3257a8;
  color: #fff;
  display: inline-block;
  padding: 0 20px;
  line-height: 30px;
  border-radius: 8px;
}
.coreal-single-blog .content .meta-items .meta-author {
  margin-left: 20px;
}
.coreal-single-blog .content .meta-items .meta-author span {
  color: #777777;
  font-size: 14px;
}
.coreal-single-blog .content .meta-items .meta-author span a {
  color: #111111;
}
.coreal-single-blog .content .meta-date {
  position: absolute;
  right: 40px;
  top: -30px;
  height: 60px;
  width: 60px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.coreal-single-blog .content .meta-date .title {
  margin-bottom: 0;
  font-size: 24px;
  line-height: 16px;
}
.coreal-single-blog .content .meta-date span {
  font-size: 14px;
}
.coreal-single-blog .content .title {
  font-size: 20px;
  line-height: 30px;
  margin-top: 12px;
}
.coreal-single-blog .content .title a {
  color: #111111;
}
.coreal-single-blog .content .title a:hover {
  color: #3257a8;
}
.coreal-single-blog:hover {
  box-shadow: none;
}
.coreal-single-blog:hover .thumb img {
  transform: scale(1.2);
}
.coreal-single-blog.coreal-single-blog-3 .thumb {
  position: relative;
}
.coreal-single-blog.coreal-single-blog-3 .thumb .meta-date {
  position: absolute;
  left: 30px;
  top: 30px;
  height: 60px;
  width: 60px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-single-blog.coreal-single-blog-3 .thumb .meta-date {
    left: 10px;
    top: 10px;
  }
}
.coreal-single-blog.coreal-single-blog-3 .thumb .meta-date .title {
  margin-bottom: 0;
  font-size: 24px;
  line-height: 16px;
  margin-top: 12px;
}
.coreal-single-blog.coreal-single-blog-3 .thumb .meta-date span {
  font-size: 14px;
}
.coreal-single-blog.coreal-single-blog-3 .content {
  padding: 30px 30px 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-single-blog.coreal-single-blog-3 .content {
    padding: 30px 10px 10px;
  }
}
@media (max-width: 767px) {
  .coreal-single-blog.coreal-single-blog-3 .content {
    padding: 30px 10px 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-single-blog.coreal-single-blog-3 .content .meta-items {
    display: block;
  }
}
.coreal-single-blog.coreal-single-blog-3
  .content
  .meta-items
  .meta-categores
  a {
  font-size: 14px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-single-blog.coreal-single-blog-3 .content .meta-items .meta-author {
    margin-left: 0;
    margin-top: 10px;
  }
}
.coreal-single-blog.coreal-single-blog-3
  .content
  .meta-items
  .meta-author
  span
  a {
  font-size: 14px;
}
.coreal-single-blog.coreal-single-blog-3 .content .title {
  font-size: 18px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-single-blog.coreal-single-blog-3 .content .title {
    font-size: 16px;
  }
}

.coreal-blog-details-item .coreal-single-blog {
  box-shadow: none;
}
.coreal-blog-details-item .coreal-single-blog .content {
  padding: 40px 0 0;
  border: 0;
}
.coreal-blog-details-item .coreal-single-blog .content > .title {
  font-size: 32px;
  line-height: 42px;
}
.coreal-blog-details-item
  .coreal-blog-related-post
  .coreal-single-blog
  .content
  > .title {
  font-size: 20px;
  line-height: 30px;
}

.coreal-blog-details-box {
  padding-top: 40px;
}
.coreal-blog-details-box .coreal-text {
  font-size: 18px;
  line-height: 32px;
}
.coreal-blog-details-box .coreal-text .coreal-title {
  font-size: 20px;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 20px;
  margin-bottom: 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-blog-details-box .coreal-text.coreal-blog-details-thumb-text {
    padding: 15px 0 0;
  }
}
@media (max-width: 767px) {
  .coreal-blog-details-box .coreal-text.coreal-blog-details-thumb-text {
    padding: 15px 0 0;
  }
}
.coreal-blog-details-box .coreal-text p {
  margin: 0 0 25px;
}
.coreal-blog-details-box .coreal-text ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-blog-details-box .coreal-text ul li {
  font-size: 18px;
  margin-bottom: 8px;
  position: relative;
  padding-left: 26px;
}
.coreal-blog-details-box .coreal-text ul li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 13px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #dedede;
}
.coreal-blog-details-box .coreal-quote-text {
  box-shadow: 0px 16px 32px 10px rgba(0, 0, 0, 0.04);
  padding: 41px 50px 45px;
  margin-top: 55px;
  position: relative;
  overflow: hidden;
}
.coreal-blog-details-box .coreal-quote-text::before {
  position: absolute;
  content: "";
  right: -35px;
  bottom: -25px;
  height: 134px;
  width: 155px;
  background-image: url(../images/icon/quote-bg.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-blog-details-box .coreal-quote-text {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-blog-details-box .coreal-quote-text {
    padding: 20px;
  }
}
.coreal-blog-details-box .coreal-quote-text p {
  font-weight: 700;
  color: #111111;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 9px;
}
@media (max-width: 767px) {
  .coreal-blog-details-box .coreal-quote-text p {
    font-size: 17px;
    line-height: 27px;
  }
}
.coreal-blog-details-box .coreal-quote-text span {
  color: #bfbfbf;
  font-style: italic;
}
.coreal-blog-details-box .coreal-quote-text span span {
  font-style: normal;
  color: #3257a8;
  padding-left: 4px;
}

.coreal-blog-details-thumb-play {
  position: relative;
}
.coreal-blog-details-thumb-play .coreal-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.coreal-blog-details-thumb-play .coreal-play a {
  height: 120px;
  width: 120px;
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 120px;
  color: #3257a8;
  font-size: 20px;
}
@media (max-width: 767px) {
  .coreal-blog-details-thumb-play .coreal-play a {
    height: 80px;
    width: 80px;
    line-height: 80px;
    font-size: 16px;
  }
}
.coreal-blog-details-thumb-play img {
  width: 100%;
}

.coreal-social-share-tag {
  border-top: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 53px;
}
.coreal-social-share-tag .coreal-tag ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-social-share-tag .coreal-tag ul li {
  display: inline-block;
}
.coreal-social-share-tag .coreal-tag ul li a {
  background: #f4f4f4;
  color: #777777;
  display: inline-block;
  line-height: 30px;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 500;
}
.coreal-social-share-tag .coreal-tag ul li a:hover {
  background: #3257a8;
  color: #fff;
}
.coreal-social-share-tag .coreal-social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-social-share-tag .coreal-social ul li {
  display: inline-block;
}
.coreal-social-share-tag .coreal-social ul li a {
  display: inline-block;
  color: #d3d3d3;
  font-size: 18px;
  margin-left: 24px;
}
@media (max-width: 767px) {
  .coreal-social-share-tag .coreal-social ul li a {
    margin-left: 0;
    margin-right: 20px;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-social-share-tag .coreal-social ul li a {
    margin-left: 20px;
    margin-right: 0px;
    margin-top: 0;
  }
}
.coreal-social-share-tag .coreal-social ul li a:hover {
  color: #3257a8;
}

.coreal-blog-post-prev-next {
  padding-top: 20px;
  padding-bottom: 13px;
  position: relative;
  border-bottom: 1px solid #f7f7f7;
}
.coreal-blog-post-prev-next .coreal-post-bars {
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .coreal-blog-post-prev-next .coreal-post-bars {
    display: none;
  }
}
.coreal-blog-post-prev-next .coreal-post-prev-next {
  width: 40%;
}
.coreal-blog-post-prev-next .coreal-post-prev-next span {
  font-size: 14px;
  color: #777777;
}
.coreal-blog-post-prev-next .coreal-post-prev-next .coreal-title {
  font-size: 16px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.coreal-blog-related-post {
  padding-top: 50px;
}
.coreal-blog-related-post .coreal-related-post-title .coreal-title {
  font-size: 20px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 19px;
  margin-bottom: 30px;
}

.coreal-blog-related-post-slide {
  margin-right: -80px;
}
@media (max-width: 767px) {
  .coreal-blog-related-post-slide {
    margin-right: 0;
  }
}
.coreal-blog-related-post-slide .coreal-video-post {
  margin-right: 30px;
}
@media (max-width: 767px) {
  .coreal-blog-related-post-slide .coreal-video-post {
    margin-right: 0;
  }
}
.coreal-blog-related-post-slide
  .coreal-video-post
  .coreal-latest-news-item
  .coreal-content {
  padding: 0;
}
.coreal-blog-related-post-slide
  .coreal-video-post
  .coreal-latest-news-item
  .coreal-content
  .coreal-meta-item
  .coreal-meta-categories {
  margin-right: 20px;
}
.coreal-blog-related-post-slide
  .coreal-video-post
  .coreal-latest-news-item
  .coreal-content
  .coreal-meta-item
  .coreal-meta-date {
  margin-left: 0;
}
.coreal-blog-related-post-slide
  .coreal-video-post
  .coreal-latest-news-item
  .coreal-content
  .coreal-title {
  font-size: 20px;
  letter-spacing: 0;
}

.coreal-blog-post-form {
  padding-top: 18px;
}
.coreal-blog-post-form .coreal-blog-post-title .coreal-title {
  font-size: 20px;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.coreal-blog-post-form .coreal-input-box {
  margin-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}
.coreal-blog-post-form .coreal-input-box i {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 14px;
  color: #ababab;
}
.coreal-blog-post-form .coreal-input-box input {
  width: 100%;
  border: 2px solid #d8d8d8;
  height: 70px;
  padding-left: 30px;
  font-size: 14px;
  color: #ababab;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 15px;
}
.coreal-blog-post-form .coreal-input-box input::placeholder {
  color: #ababab;
  opacity: 1;
}
.coreal-blog-post-form .coreal-input-box input:focus,
.coreal-blog-post-form .coreal-input-box input:hover {
  box-shadow: none;
}
.coreal-blog-post-form .coreal-input-box textarea {
  width: 100%;
  border: 2px solid #d8d8d8;
  height: 250px;
  padding-left: 30px;
  padding-top: 20px;
  font-size: 14px;
  color: #ababab;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  resize: none;
  border-radius: 15px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.coreal-blog-post-form .coreal-input-box textarea::placeholder {
  color: #ababab;
  opacity: 1;
}
.coreal-blog-post-form .coreal-input-box textarea:focus,
.coreal-blog-post-form .coreal-input-box textarea:hover {
  box-shadow: none;
}
.coreal-blog-post-form .coreal-input-box .nice-select {
  width: 100%;
  height: 70px;
  padding: 0 30px;
  line-height: 70px;
  color: #ababab;
  border: 2px solid #d8d8d8;
  border-radius: 0;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-blog-post-form .coreal-input-box .nice-select {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .coreal-blog-post-form .coreal-input-box .nice-select {
    margin-bottom: 20px;
  }
}
.coreal-blog-post-form .coreal-input-box .nice-select::after {
  display: none;
}
.coreal-blog-post-form .coreal-input-box .nice-select:focus,
.coreal-blog-post-form .coreal-input-box .nice-select:hover {
  box-shadow: none;
}
.coreal-blog-post-form .coreal-input-box .nice-select .list {
  border-radius: 0;
  width: 100%;
}
.coreal-blog-post-form .coreal-input-box button {
  border-radius: 0;
  padding: 0 35px;
  line-height: 60px;
  background: #3257a8;
  color: #fff;
  border-radius: 15px;
  border: 2px solid #3257a8;
  transition: all linear 0.2s;
}
.coreal-blog-post-form .coreal-input-box button:hover {
  background-color: transparent;
  color: #3257a8;
}

.coreal-blog-project-item {
  position: relative;
}
@media (max-width: 767px) {
  .coreal-blog-project-item {
    margin-bottom: 30px;
  }
}
.coreal-blog-project-item img {
  width: 100%;
}
.coreal-blog-project-item .coreal-blog-project-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 75%
  );
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.coreal-blog-project-item .coreal-blog-project-overlay a {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 26px;
}

.coreal-blog-post-newsletter {
  padding-bottom: 35px;
}
@media (max-width: 767px) {
  .coreal-blog-post-newsletter {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-blog-post-newsletter {
    display: block;
  }
}
.coreal-blog-post-newsletter
  .coreal-populer-news-sidebar-newsletter
  .coreal-newsletter-box {
  padding: 60px 110px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-blog-post-newsletter
    .coreal-populer-news-sidebar-newsletter
    .coreal-newsletter-box {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-blog-post-newsletter
    .coreal-populer-news-sidebar-newsletter
    .coreal-newsletter-box {
    padding: 20px;
  }
}
.coreal-blog-post-newsletter
  .coreal-populer-news-sidebar-newsletter
  .coreal-newsletter-box::before {
  display: none;
}
.coreal-blog-post-newsletter
  .coreal-populer-news-sidebar-newsletter
  .coreal-newsletter-box
  p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding-top: 35px;
  margin-bottom: 0;
}
.coreal-blog-post-newsletter
  .coreal-populer-news-sidebar-newsletter
  .coreal-newsletter-box
  .coreal-title {
  padding-top: 6px;
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 25px;
}
.coreal-blog-post-newsletter
  .coreal-populer-news-sidebar-newsletter
  .coreal-newsletter-box
  .coreal-input-box
  button {
  background: #ea5f69;
  border-left: 10px solid #3257a8;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
@media (max-width: 767px) {
  .coreal-blog-post-newsletter
    .coreal-populer-news-sidebar-newsletter
    .coreal-newsletter-box
    .coreal-input-box
    button {
    position: static;
    border-left: 0;
    transform: translateY(0%);
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-blog-post-newsletter
    .coreal-populer-news-sidebar-newsletter
    .coreal-newsletter-box
    .coreal-input-box
    button {
    position: absolute;
    border-left: 10px solid;
    transform: translateY(-50%);
    margin-top: 00px;
  }
}
.coreal-blog-post-newsletter
  .coreal-populer-news-sidebar-newsletter
  .coreal-newsletter-box
  .coreal-input-box
  input {
  font-style: italic;
  color: #777777;
}
.coreal-blog-post-newsletter
  .coreal-populer-news-sidebar-newsletter
  .coreal-newsletter-box
  .coreal-input-box
  input::placeholder {
  opacity: 1;
  color: #777777;
}

.coreal-blog-social-share {
  padding-left: 50px;
  margin-right: -50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-blog-social-share {
    display: flex;
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-blog-social-share {
    display: block;
    padding-left: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-blog-social-share {
    display: flex;
    padding-left: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-blog-social-share .coreal-item {
    width: 20%;
  }
}
@media (max-width: 767px) {
  .coreal-blog-social-share .coreal-item {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-blog-social-share .coreal-item {
    width: 25%;
  }
}
.coreal-blog-social-share .coreal-item a {
  display: block;
}
.coreal-blog-social-share .coreal-item a i {
  background: #3b5999;
  color: #fff;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  float: left;
  margin-right: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-blog-social-share .coreal-item a i {
    float: none;
  }
}
.coreal-blog-social-share .coreal-item a span {
  font-size: 16px;
  font-weight: 700;
  color: #111111;
  margin-top: -10px;
  display: inline-block;
  line-height: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-blog-social-share .coreal-item a span {
    display: block;
    margin-top: 10px;
  }
}
.coreal-blog-social-share .coreal-item a p {
  color: #777777;
  margin-bottom: 0;
}
.coreal-blog-social-share .coreal-item:nth-child(2) a i {
  background: #55acee;
}
.coreal-blog-social-share .coreal-item:nth-child(3) a i {
  background: #cd201f;
}

.coreal-archived-sidebar-about {
  border: 2px solid #dee2e6;
  text-align: center;
  margin-bottom: 40px;
  padding: 50px 0 50px;
  border-radius: 15px;
}
.coreal-archived-sidebar-about .coreal-user {
  height: 140px;
  width: 140px;
  display: inline-block;
  position: relative;
}
.coreal-archived-sidebar-about .coreal-user .coreal-icon {
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  background: #fff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  line-height: 50px;
  color: #3257a8;
}
.coreal-archived-sidebar-about span {
  font-size: 14px;
  text-transform: capitalize;
  padding-top: 22px;
  padding-bottom: 3px;
  display: block;
}
.coreal-archived-sidebar-about .coreal-title {
  font-size: 20px;
}
.coreal-archived-sidebar-about ul {
  margin: 43px 0 0;
  padding: 0;
  list-style-type: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
}
.coreal-archived-sidebar-about ul li {
  display: inline-block;
}
.coreal-archived-sidebar-about ul li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background: #efefef;
  color: #777777;
  text-align: center;
  line-height: 40px;
  margin: 0 4px;
}
.coreal-archived-sidebar-about ul li:nth-child(2):hover a {
  background: #55acee;
}
.coreal-archived-sidebar-about ul li:nth-child(3):hover a {
  background: #0056ff;
}
.coreal-archived-sidebar-about ul li:nth-child(4):hover a {
  background: #cd201f;
}
.coreal-archived-sidebar-about ul li:hover a {
  background: #3b5999;
  color: #fff;
}

.coreal-author-sidebar-search-bar .coreal-input-box {
  position: relative;
}
.coreal-author-sidebar-search-bar .coreal-input-box button {
  position: absolute;
  right: 0;
  top: 0;
  background: #3257a8;
  height: 100%;
  width: 62px;
  border: 0;
  color: #fff;
  font-size: 18px;
  border-radius: 15px;
}
.coreal-author-sidebar-search-bar .coreal-input-box input {
  width: 100%;
  border: 0;
  background: #fff;
  line-height: 60px;
  padding-left: 30px;
  color: #111111;
  border: 2px solid #dee2e6;
  border-radius: 15px;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
}
.coreal-author-sidebar-search-bar .coreal-input-box input::placeholder {
  opacity: 1;
  color: #d2d2d2;
  font-style: italic;
}

.coreal-populer-news-sidebar-post .coreal-popular-news-title {
  padding-top: 12px;
  padding-bottom: 15px;
}
.coreal-populer-news-sidebar-post .coreal-popular-news-title ul li.nav-item {
  margin-left: 0;
  margin-right: 30px;
}
.coreal-populer-news-sidebar-post .coreal-popular-news-title ul li.nav-item a {
  color: #dedede;
  padding: 0;
}
.coreal-populer-news-sidebar-post
  .coreal-popular-news-title
  ul
  li.nav-item
  a.active {
  color: #3257a8;
  background: transparent;
}
.coreal-populer-news-sidebar-post .coreal-sidebar-latest-post-box {
  margin-top: 0;
}
.coreal-populer-news-sidebar-post
  .coreal-sidebar-latest-post-box
  .coreal-sidebar-latest-post-item
  .coreal-thumb {
  border-radius: 0;
}

.coreal-sidebar-latest-post-box {
  margin-top: 35px;
}
.coreal-sidebar-latest-post-box .coreal-sidebar-latest-post-item {
  margin-bottom: 20px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-sidebar-latest-post-box .coreal-sidebar-latest-post-item {
    display: block;
  }
}
.coreal-sidebar-latest-post-box .coreal-sidebar-latest-post-item .coreal-thumb {
  max-width: 80px;
  height: 80px;
  margin-right: 30px;
  border-radius: 10px;
  overflow: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-sidebar-latest-post-box
    .coreal-sidebar-latest-post-item
    .coreal-thumb {
    margin-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-sidebar-latest-post-box
    .coreal-sidebar-latest-post-item
    .coreal-thumb {
    margin-bottom: 10px;
  }
}
.coreal-sidebar-latest-post-box
  .coreal-sidebar-latest-post-item
  .coreal-content
  span {
  font-size: 14px;
  color: #666;
}
.coreal-sidebar-latest-post-box
  .coreal-sidebar-latest-post-item
  .coreal-content
  .coreal-title {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-sidebar-latest-post-box
    .coreal-sidebar-latest-post-item
    .coreal-content
    .coreal-title {
    font-size: 15px;
    line-height: 25px;
  }
}
.coreal-sidebar-latest-post-box
  .coreal-sidebar-latest-post-item
  .coreal-content
  .coreal-title
  a {
  color: #111111;
}
.coreal-sidebar-latest-post-box
  .coreal-sidebar-latest-post-item
  .coreal-content
  .coreal-title
  a:hover {
  color: #3257a8;
}

.coreal-newsletter-box {
  padding: 72px 50px;
  background: #3257a8;
  position: relative;
  z-index: 10;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-newsletter-box {
    padding: 40px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-newsletter-box {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-newsletter-box {
    margin-bottom: 30px;
    padding: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-newsletter-box {
    margin-bottom: 30px;
    padding: 72px 65px;
  }
}
.coreal-newsletter-box::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* background-image: url(../images/newsletter-news.jpg); */
  z-index: -1;
  opacity: 0.1;
}
.coreal-newsletter-box .coreal-title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  padding-top: 22px;
  margin-bottom: 4px;
}
.coreal-newsletter-box p {
  color: #a1bbce;
  font-size: 14px;
  margin-bottom: 24px;
}
.coreal-newsletter-box .coreal-input-box {
  position: relative;
}
.coreal-newsletter-box .coreal-input-box button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background-color: transparent;
  color: #3257a8;
  line-height: 60px;
  padding: 0 30px;
  font-size: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-newsletter-box .coreal-input-box button {
    right: -1px;
  }
}
.coreal-newsletter-box .coreal-input-box input {
  width: 92%;
  height: 58px;
  padding: 0;
  background: #fff;
  color: #fff;
  border: 0;
  padding-left: 30px;
  color: #777777;
}
.coreal-newsletter-box .coreal-input-box input::placeholder {
  opacity: 1;
  color: #79a2c0;
}

.coreal-popular-news-title {
  border-top: 2px solid #f2f2f2;
  padding-top: 28px;
  padding-bottom: 20px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .coreal-popular-news-title {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-popular-news-title {
    display: flex;
  }
}
.coreal-popular-news-title .coreal-title {
  color: #3257a8;
  font-size: 18px;
  font-weight: 500;
}
.coreal-popular-news-title ul li.nav-item {
  margin-left: 32px;
}
@media (max-width: 767px) {
  .coreal-popular-news-title ul li.nav-item {
    margin-left: 0;
    margin-right: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-popular-news-title ul li.nav-item {
    margin-left: 20;
    margin-right: 0px;
  }
}
.coreal-popular-news-title ul li.nav-item a {
  padding: 0;
  color: #999;
}
.coreal-popular-news-title ul li.nav-item a.active {
  color: #000;
  background-color: transparent;
}

.coreal-populer-news-social .coreal-social-list .coreal-list a {
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 0px;
  padding: 10px 30px 10px 10px;
  align-items: center;
  margin-bottom: 8px;
}
.coreal-populer-news-social .coreal-social-list .coreal-list a:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}
.coreal-populer-news-social .coreal-social-list .coreal-list a span {
  font-size: 14px;
  color: #111111;
}
.coreal-populer-news-social .coreal-social-list .coreal-list a span span {
  font-weight: 700;
}
.coreal-populer-news-social .coreal-social-list .coreal-list a span i {
  height: 40px;
  width: 40px;
  background: #3b5999;
  text-align: center;
  line-height: 40px;
  color: #fff;
  border-radius: 0px;
  margin-right: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-populer-news-social .coreal-social-list .coreal-list a span i {
    margin-right: 5px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-populer-news-social .coreal-social-list .coreal-list a span i {
    margin-right: 5px;
  }
}
.coreal-populer-news-social
  .coreal-social-list
  .coreal-list
  a:nth-child(2)
  span
  i {
  background: #55acee;
}
.coreal-populer-news-social
  .coreal-social-list
  .coreal-list
  a:nth-child(3)
  span
  i {
  background: #0057ff;
}
.coreal-populer-news-social
  .coreal-social-list
  .coreal-list
  a:nth-child(4)
  span
  i {
  background: #cd201f;
}

.coreal-video-post .coreal-latest-news-item {
  display: block;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.coreal-video-post .coreal-latest-news-item .coreal-thumb {
  width: 100%;
  margin-right: 0;
  margin-bottom: 20px;
  position: relative;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-video-post .coreal-latest-news-item .coreal-thumb {
    min-width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-video-post .coreal-latest-news-item .coreal-thumb {
    min-width: 100%;
  }
}
.coreal-video-post .coreal-latest-news-item .coreal-thumb img {
  width: 100%;
}
.coreal-video-post .coreal-latest-news-item .coreal-thumb .coreal-play {
  position: absolute;
  right: 20px;
  top: 20px;
}
.coreal-video-post .coreal-latest-news-item .coreal-thumb .coreal-play a {
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #3257a8;
  color: #fff;
  border-radius: 50%;
}
.coreal-video-post
  .coreal-latest-news-item
  .coreal-content
  .coreal-meta-categories {
  display: inline-block;
}
.coreal-video-post
  .coreal-latest-news-item
  .coreal-content
  .coreal-meta-categories
  a {
  display: inline-block;
  background: #e6f4ff;
  line-height: 30px;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 700;
  color: #3257a8;
}
.coreal-video-post .coreal-latest-news-item .coreal-content .coreal-meta-date {
  margin-left: 20px;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-video-post
    .coreal-latest-news-item
    .coreal-content
    .coreal-meta-date {
    margin-left: 15px;
  }
}
.coreal-video-post
  .coreal-latest-news-item
  .coreal-content
  .coreal-meta-date
  span {
  font-size: 14px;
}
.coreal-video-post
  .coreal-latest-news-item
  .coreal-content
  .coreal-meta-date
  span
  i {
  padding-right: 6px;
}
.coreal-video-post .coreal-latest-news-item .coreal-content .coreal-title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  margin-top: 10px;
}
.coreal-video-post .coreal-latest-news-item .coreal-content .coreal-title a {
  color: #111111;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-video-post .coreal-latest-news-item .coreal-content .coreal-title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .coreal-video-post .coreal-latest-news-item .coreal-content .coreal-title {
    font-size: 18px;
  }
}

.coreal-sidebar-add {
  background-image: url(../images/space-bg-1.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  padding: 14px 20px 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-sidebar-add {
    width: 40%;
  }
}
.coreal-sidebar-add .coreal-title {
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  text-transform: inherit;
}
.coreal-sidebar-add .coreal-title span {
  font-weight: 300;
  display: block;
}
.coreal-sidebar-add a {
  background: #3257a8;
  color: #fff;
  border-color: #3257a8;
  padding: 0 30px;
  line-height: 40px;
  display: inline-block;
  border-radius: 10px;
}

/*===========================
    17. COREAL ADD LISTING	 css 
===========================*/
.coreal-add-listing-area {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #f9f9f9;
}
.coreal-add-listing-area .coreal-add-listing-box {
  background: #fff;
  padding: 75px 80px 80px;
}
@media (max-width: 767px) {
  .coreal-add-listing-area .coreal-add-listing-box {
    padding: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-add-listing-area .coreal-add-listing-box {
    padding: 30px;
  }
}
.coreal-add-listing-area .coreal-add-listing-box .input-box {
  position: relative;
  margin-top: 20px;
}
.coreal-add-listing-area .coreal-add-listing-box .input-box input {
  width: 100%;
  height: 60px;
  border: 2px solid #f0f0f0;
  padding-left: 30px;
  font-size: 14px;
  color: #999;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
}
.coreal-add-listing-area .coreal-add-listing-box .input-box input::placeholder {
  opacity: 1;
  color: #999;
}
.coreal-add-listing-area .coreal-add-listing-box .input-box > i {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #999;
}
.coreal-add-listing-area
  .coreal-add-listing-box
  .input-box
  .coreal-select-item
  .nice-select {
  width: 100%;
  float: none;
  height: 60px;
  border: 2px;
  border: 2px solid #f0f0f0;
  border-radius: 0;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  padding-left: 30px;
  line-height: 56px;
  font-size: 14px;
  color: #999;
}
.coreal-add-listing-area
  .coreal-add-listing-box
  .input-box
  .coreal-select-item
  .nice-select::after {
  display: none;
}
.coreal-add-listing-area
  .coreal-add-listing-box
  .input-box
  .coreal-select-item
  .nice-select
  .list {
  width: 100%;
}
.coreal-add-listing-area
  .coreal-add-listing-box
  .input-box
  .coreal-select-item
  .nice-select
  .list
  li {
  padding-left: 30px;
}
.coreal-add-listing-area .coreal-add-listing-box .input-box textarea {
  width: 100%;
  height: 200px;
  border: 2px solid #f0f0f0;
  padding-left: 30px;
  padding-top: 20px;
  font-size: 14px;
  color: #999;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  resize: none;
}
.coreal-add-listing-area
  .coreal-add-listing-box
  .input-box
  textarea::placeholder {
  opacity: 1;
  color: #999;
}
.coreal-add-listing-area .coreal-add-listing-box .input-box .box__input {
  position: relative;
}
.coreal-add-listing-area
  .coreal-add-listing-box
  .input-box
  .box__input
  .box__file {
  display: none;
}
.coreal-add-listing-area .coreal-add-listing-box .input-box .box__input label {
  position: absolute;
  right: 0;
  top: -60px;
  line-height: 60px;
  width: 200px;
  text-align: center;
  background: #3257a8;
  color: #fff;
  cursor: pointer;
}
@media (max-width: 767px) {
  .coreal-add-listing-area
    .coreal-add-listing-box
    .input-box
    .box__input
    label {
    position: static;
    margin-top: 20px;
  }
}
.coreal-add-listing-area .coreal-add-listing-box .coreal-add-listing-upload {
  border: 2px solid #f0f0f0;
  margin-top: 20px;
  height: 380px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  position: relative;
}
.coreal-add-listing-area
  .coreal-add-listing-box
  .coreal-add-listing-upload
  .box__file {
  display: none;
}
.coreal-add-listing-area
  .coreal-add-listing-box
  .coreal-add-listing-upload
  .box__input {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.coreal-add-listing-area
  .coreal-add-listing-box
  .coreal-add-listing-upload
  .box__input
  label {
  cursor: pointer;
}
.coreal-add-listing-area
  .coreal-add-listing-box
  .coreal-add-listing-upload
  .box__input
  label
  strong {
  font-size: 24px;
  font-weight: 700;
  color: #111111;
  margin-top: 12px;
  display: inline-block;
}
.coreal-add-listing-area
  .coreal-add-listing-box
  .coreal-add-listing-upload
  .box__input
  .coreal-input-thumb
  img {
  width: 100%;
  font-size: 0;
}

.coreal-add-listing-title {
  position: relative;
}
.coreal-add-listing-title .title {
  font-size: 20px;
  margin-bottom: 5px;
}
.coreal-add-listing-title p {
  font-size: 14px;
}
.coreal-add-listing-title span {
  display: inline-block;
  position: absolute;
  left: -42px;
  top: 5px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 26px;
  border: 2px solid #f0f0f0;
  font-size: 12px;
  color: #3257a8;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
  font-weight: 700;
}
@media (max-width: 767px) {
  .coreal-add-listing-title span {
    position: static;
    margin-bottom: 10px;
  }
}

.coreal-add-listing-upload-file-box {
  text-align: center;
  border: 2px solid #f0f0f0;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  padding: 40px 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-add-listing-upload-file-box {
    padding: 40px 15px;
  }
}
@media (max-width: 767px) {
  .coreal-add-listing-upload-file-box {
    padding: 40px 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-add-listing-upload-file-box {
    padding: 40px 60px;
  }
}
.coreal-add-listing-upload-file-box .icon {
  height: 100px;
  width: 100px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  border: 2px solid #f0f0f0;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  font-size: 30px;
  color: #3257a8;
}
.coreal-add-listing-upload-file-box .coreal-add-listing-modal button {
  background: #f9f9f9;
  line-height: 50px;
  color: #c5c5c5;
  padding: 0 20px;
  border: 0;
  font-weight: 700;
  border-radius: 0;
}
.coreal-add-listing-upload-file-box
  .coreal-add-listing-modal
  .coreal-add-listing-modal
  .modal-content
  .modal-body {
  text-align: left;
  padding: 60px;
}
@media (max-width: 767px) {
  .coreal-add-listing-upload-file-box
    .coreal-add-listing-modal
    .coreal-add-listing-modal
    .modal-content
    .modal-body {
    padding: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-add-listing-upload-file-box
    .coreal-add-listing-modal
    .coreal-add-listing-modal
    .modal-content
    .modal-body {
    padding: 60px;
  }
}
.coreal-add-listing-upload-file-box
  .coreal-add-listing-modal
  .coreal-add-listing-modal
  .modal-content
  .modal-body
  .coreal-property-title {
  font-size: 24px;
  border-bottom: 2px solid #eee;
}
.coreal-add-listing-upload-file-box
  .coreal-add-listing-modal
  .coreal-add-listing-modal
  .modal-content
  .modal-body
  .coreal-property-form
  input[type="text"] {
  width: 100%;
  height: 60px;
  border: 2px solid #f0f0f0;
  padding-left: 30px;
  font-size: 14px;
  color: #999;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  margin-top: 20px;
}
.coreal-add-listing-upload-file-box
  .coreal-add-listing-modal
  .coreal-add-listing-modal
  .modal-content
  .modal-body
  .coreal-property-form
  input[type="text"]::placeholder {
  opacity: 1;
  color: #999;
}
.coreal-add-listing-upload-file-box
  .coreal-add-listing-modal
  .coreal-add-listing-modal
  .modal-content
  .modal-body
  .coreal-property-form
  input[type="button"] {
  border: 0;
  display: block;
  background: #3257a8;
  line-height: 40px;
  padding: 0 20px;
  color: #fff;
  font-weight: 700;
  margin-top: 20px;
}
.coreal-add-listing-upload-file-box
  .coreal-add-listing-modal
  .coreal-add-listing-modal
  .modal-content
  .modal-body
  .coreal-property-form
  .coreal-property-document-upload
  input {
  margin-top: 20px;
  cursor: pointer;
  display: inline-block;
  color: #3257a8;
  line-height: 30px;
  background: #fff;
  width: 100%;
}
.coreal-add-listing-upload-file-box .title {
  font-size: 20px;
  padding-top: 20px;
}
.coreal-add-listing-upload-file-box p {
  font-size: 14px;
  line-height: 24px;
  color: #999;
  margin-bottom: 25px;
}

.coreal-checkbox-box {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.coreal-checkbox-box li i {
  color: #e3e3e3;
}

.coreal-checkbox-box li label {
  color: #777;
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
  margin-bottom: 8px;
}

.coreal-checkbox-box li input[type="checkbox"] {
  display: none;
}

.coreal-checkbox-box li input[type="checkbox"] + label span {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: -1px 15px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #ededed;
  position: relative;
}

.coreal-checkbox-box li input[type="checkbox"] + label span:before {
  color: #fff;
  content: "\f00c";
  font-family: "FontAwesome";
  font-size: 11px;
  position: absolute;
  text-align: center;
  left: 3px;
  top: -4px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.coreal-checkbox-box li input[type="checkbox"]:checked + label span {
  background: #2079ff;
  border-color: #2079ff;
}

.coreal-checkbox-box li input[type="checkbox"]:checked + label span:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.coreal-checkbox-box li input[type="checkbox"] + label span,
.coreal-checkbox-box li input[type="checkbox"]:checked + label span {
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.coreal-checkbox-box.checkbox_style6 li input[type="checkbox"] + label span {
  border-radius: 100%;
  border-color: #eee;
  background: transparent;
}

.coreal-checkbox-box.checkbox_style6
  li
  input[type="checkbox"]
  + label
  span::before {
  background: #fff;
  content: "";
  height: 100%;
  left: 0;
  top: 0;
  transform: scale(1);
  width: 100%;
  border-radius: 100%;
}

.coreal-checkbox-box.checkbox_style6
  li
  input[type="checkbox"]:checked
  + label
  span {
  border-color: #3257a8;
}

.coreal-checkbox-box.checkbox_style6
  li
  input[type="checkbox"]:checked
  + label
  span::before {
  transform: scale(0.6);
  background: #3257a8;
}

.coreal-add-listing-submit-btn {
  padding-top: 35px;
}
.coreal-add-listing-submit-btn ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-add-listing-submit-btn ul li {
  display: inline-block;
}
@media (max-width: 767px) {
  .coreal-add-listing-submit-btn ul li {
    display: block;
    margin-bottom: 20px;
  }
}
.coreal-add-listing-submit-btn ul li button {
  border: 2px solid #3257a8;
  background: #3257a8;
  color: #fff;
  font-weight: 700;
  line-height: 60px;
  padding: 0 40px;
  margin-right: 20px;
}
.coreal-add-listing-submit-btn ul li a {
  border: 2px solid #111111;
  background: #111111;
  color: #fff;
  font-weight: 700;
  line-height: 60px;
  padding: 0 40px;
  display: inline-block;
}
.coreal-add-listing-submit-btn ul li a i {
  padding-right: 4px;
}

.coreal-map #coreal-map {
  width: 100%;
  height: 380px;
}

.coreal-geocoder svg {
  right: 30px;
  top: 50% !important;
  left: auto !important;
  transform: translateY(-50%);
}
.coreal-geocoder .mapboxgl-ctrl-geocoder {
  width: 100%;
  box-shadow: none !important;
}
.coreal-geocoder input {
  box-shadow: none !important;
}
.coreal-geocoder
  .mapboxgl-ctrl-geocoder--pin-right
  .mapboxgl-ctrl-geocoder--icon-close {
  position: absolute;
  left: -345px !important;
  top: 4px !important;
}

.coreal-listing-details-properties-list {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  overflow: hidden;
  background: #fff;
  display: flex;
  margin-top: 45px;
}
@media (max-width: 767px) {
  .coreal-listing-details-properties-list {
    display: block;
  }
}
.coreal-listing-details-properties-list .thumb {
  position: relative;
  overflow: hidden;
  min-width: 400px;
  max-width: 400px;
  background-image: url(../images/listed-property-list-thumb-1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-listing-details-properties-list .thumb {
    min-width: 50%;
    max-width: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-listing-details-properties-list .thumb {
    min-width: 260px;
    max-width: 260px;
  }
}
@media (max-width: 767px) {
  .coreal-listing-details-properties-list .thumb {
    min-width: 100%;
    max-width: 100%;
    height: 300px;
  }
}
.coreal-listing-details-properties-list .thumb::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
}
.coreal-listing-details-properties-list .thumb > i {
  position: absolute;
  right: 25px;
  top: 25px;
  color: #fff;
}
.coreal-listing-details-properties-list .thumb a {
  position: absolute;
  left: 30px;
  bottom: 30px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  line-height: 36px;
  color: #fff;
  font-size: 14px;
  padding: 0 10px;
}
.coreal-listing-details-properties-list .thumb a span {
  font-weight: 700;
}
.coreal-listing-details-properties-list .thumb a i {
  padding-right: 2px;
}
.coreal-listing-details-properties-list .thumb a:hover {
  background: #3257a8;
  border-color: #3257a8;
}
.coreal-listing-details-properties-list .thumb .author {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.coreal-listing-details-properties-list .thumb .author img {
  border-radius: 12px;
  border: 3px solid #fff;
}
.coreal-listing-details-properties-list .content {
  border: 2px solid #ededed;
  border-left: 0;
  border-radius: 0px 14px 14px 0px;
  padding: 44px 50px;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .coreal-listing-details-properties-list .content {
    padding: 44px 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-listing-details-properties-list .content {
    padding: 44px 20px;
  }
}
@media (max-width: 767px) {
  .coreal-listing-details-properties-list .content {
    padding: 44px 20px;
    border-left: 2px solid #ededed;
    border-radius: 0px 0px 14px 14px;
  }
}
.coreal-listing-details-properties-list .content .title {
  font-size: 24px;
  color: #3257a8;
  margin-bottom: 11px;
}
.coreal-listing-details-properties-list .content a {
  font-size: 18px;
  color: #111111;
  line-height: 26px;
  margin-bottom: 12px;
  display: inline-block;
}
.coreal-listing-details-properties-list .content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-listing-details-properties-list .content ul li {
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #dddddd;
  line-height: 12px;
  font-size: 14px;
}
.coreal-listing-details-properties-list .content ul li i {
  color: #3257a8;
  padding-right: 6px;
}
.coreal-listing-details-properties-list .content ul li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: 0;
}

/*===========================
    18. COREAL AGENTS css 
===========================*/
.coreal-agents-area {
  background: #f8f8f8;
  padding-top: 290px;
  padding-bottom: 100px;
  margin-top: -200px;
}

.coreal-agents-item {
  border-radius: 14px;
  background-color: #fefefe;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-agents-item {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-agents-item {
    margin-top: 30px;
    border-radius: 25px;
  }
}
.coreal-agents-item .thumb img {
  width: 100%;
}
.coreal-agents-item .content {
  padding: 29px 40px 31px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-agents-item .content {
    padding: 29px 20px 31px;
  }
}
@media (max-width: 767px) {
  .coreal-agents-item .content {
    padding: 29px 20px 31px;
  }
}
.coreal-agents-item .content span {
  font-size: 14px;
  color: #3257a8;
  font-weight: 700;
}
.coreal-agents-item .content .title {
  font-size: 24px;
  margin-bottom: 17;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-agents-item .content .title {
    font-size: 20px;
  }
}
.coreal-agents-item .content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-agents-item .content ul li {
  line-height: 30px;
}
.coreal-agents-item .content ul li a {
  color: #777777;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-agents-item .content ul li a {
    font-size: 14px;
  }
}
.coreal-agents-item .content ul li i {
  margin-right: 18px;
  width: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-agents-item .content ul li i {
    margin-right: 0;
  }
}
.coreal-agents-item.coreal-agents-item-page .content {
  border: 2px solid #ededed;
  border-top: 0;
  border-radius: 0 0 15px 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-agents-item.coreal-agents-item-page .content {
    padding: 29px 20px 31px;
  }
}
.coreal-agents-item.coreal-agents-item-page .content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-agents-item.coreal-agents-item-page .content ul li {
  font-size: 18px;
  margin-bottom: 16px;
}
.coreal-agents-item.coreal-agents-item-page .content ul li a {
  color: #777777;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-agents-item.coreal-agents-item-page .content ul li {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .coreal-agents-item.coreal-agents-item-page .content ul li {
    font-size: 15px;
  }
}
.coreal-agents-item.coreal-agents-item-page .content ul li i {
  height: 50px;
  width: 50px;
  text-align: center;
  border: 2px solid #e1e1e1;
  color: #3257a8;
  line-height: 46px;
  border-radius: 10px;
  margin-right: 14px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}

.coral-agents-details-area .coral-agents-details-thumb img {
  border-radius: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coral-agents-details-area .coral-agents-details-thumb img {
    width: 100%;
  }
}

.coral-agents-details-content {
  padding-left: 30px;
  margin-top: -5px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coral-agents-details-content {
    padding-left: 0;
    padding-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coral-agents-details-content {
    padding-left: 0;
    padding-top: 30px;
  }
}
@media (max-width: 767px) {
  .coral-agents-details-content {
    padding-left: 0;
    padding-top: 30px;
  }
}
.coral-agents-details-content > span {
  font-size: 16px;
  font-weight: 700;
  color: #3257a8;
}
.coral-agents-details-content .title {
  font-size: 60px;
  margin-bottom: 15px;
  padding-top: 6px;
  line-height: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coral-agents-details-content .title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 767px) {
  .coral-agents-details-content .title {
    font-size: 28px;
    line-height: 36px;
  }
}
.coral-agents-details-content .coreal-contact-info {
  padding-top: 15px;
}
.coral-agents-details-content .coreal-contact-info .item {
  padding-left: 80px;
  position: relative;
}
@media (max-width: 767px) {
  .coral-agents-details-content .coreal-contact-info .item {
    padding-left: 65px;
  }
}
.coral-agents-details-content .coreal-contact-info .item .icon {
  position: absolute;
  left: 0;
  top: 3px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 46px;
  border: 2px solid #e7e7e7;
  border-radius: 8px;
  color: #3257a8;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.coral-agents-details-content .coreal-contact-info .item h5 {
  font-size: 20px;
  margin-bottom: 20px;
}
.coral-agents-details-content .agent-contact-info {
  border-top: 0;
  padding-top: 20px;
}

/*===========================
    19. COREAL MY LISTING css 
===========================*/
.coreal-my-listing-area {
  padding-bottom: 55px;
}

.coreal-my-listing-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 12px;
}
@media (max-width: 767px) {
  .coreal-my-listing-topbar {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-my-listing-topbar {
    display: flex;
  }
}
.coreal-my-listing-topbar .title {
  font-size: 24px;
  margin-bottom: 0;
}
.coreal-my-listing-topbar ul.nav .nav-item button {
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  color: #999;
  border: 0;
  background: transparent;
  margin-left: 32px;
}
@media (max-width: 767px) {
  .coreal-my-listing-topbar ul.nav .nav-item button {
    margin-left: 0;
    margin-right: 20px;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-my-listing-topbar ul.nav .nav-item button {
    margin-left: 32px;
    margin-right: 0px;
    margin-top: 0px;
  }
}
.coreal-my-listing-topbar ul.nav .nav-item button.active {
  color: #111111;
}

/*===========================
    20. COREAL CONTACT css 
===========================*/
.coreal-contact-map iframe {
  width: 100%;
  height: 520px;
  margin-bottom: -10px;
}

.coreal-er-contact-us-area {
  background: #f8f8f8;
  padding-bottom: 100px;
}

.coreal-er-contact-us-box {
  margin-left: 50px;
  margin-right: 50px;
  background: #fff;
  padding: 54px 60px 60px;
  margin-top: -87px;
  border-radius: 14px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-er-contact-us-box {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-er-contact-us-box {
    margin-left: 0px;
    margin-right: 0px;
    padding: 20px;
    margin-top: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coreal-er-contact-us-box {
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px;
    margin-top: -87px;
  }
}
.coreal-er-contact-us-box form {
  position: relative;
}
.coreal-er-contact-us-box form button {
  position: absolute;
  left: 50%;
  bottom: -90px;
  transform: translateX(-50%);
  border-radius: 0;
  background: #e74d58;
  border: 0;
  line-height: 60px;
  padding: 0 42px;
  background: #3257a8;
  color: #fff;
  border-radius: 14px;
  transition: all linear 0.2s;
  white-space: nowrap;
}
.coreal-er-contact-us-box form button:hover {
  background: #fff;
  color: #3257a8;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
}
.coreal-er-contact-us-box .coreal-er-contact-title .coreal-er-title {
  font-size: 20px;
  border-bottom: 1px solid #dadada;
  padding-bottom: 25px;
}
.coreal-er-contact-us-box .coreal-er-input-box {
  margin-top: 20px;
  position: relative;
}
.coreal-er-contact-us-box .coreal-er-input-box i {
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 14px;
  color: #ababab;
}
.coreal-er-contact-us-box .coreal-er-input-box input {
  width: 100%;
  border: 2px solid #e8e8e8;
  height: 60px;
  padding-left: 30px;
  font-size: 14px;
  color: #ababab;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.coreal-er-contact-us-box .coreal-er-input-box input::placeholder {
  color: #ababab;
  opacity: 1;
}
.coreal-er-contact-us-box .coreal-er-input-box input:focus,
.coreal-er-contact-us-box .coreal-er-input-box input:hover {
  box-shadow: none;
}
.coreal-er-contact-us-box .coreal-er-input-box textarea {
  width: 100%;
  border: 2px solid #e8e8e8;
  height: 250px;
  padding-left: 30px;
  padding-top: 20px;
  font-size: 14px;
  color: #ababab;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  resize: none;
  border-radius: 14px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.coreal-er-contact-us-box .coreal-er-input-box textarea::placeholder {
  color: #ababab;
  opacity: 1;
}
.coreal-er-contact-us-box .coreal-er-input-box textarea:focus,
.coreal-er-contact-us-box .coreal-er-input-box textarea:hover {
  box-shadow: none;
}
.coreal-er-contact-us-box .coreal-er-input-box .nice-select {
  width: 100%;
  height: 60px;
  padding: 0 30px;
  line-height: 60px;
  color: #ababab;
  border: 2px solid #e8e8e8;
  border-radius: 0;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 14px;
}
.coreal-er-contact-us-box .coreal-er-input-box .nice-select::after {
  display: none;
}
.coreal-er-contact-us-box .coreal-er-input-box .nice-select:focus,
.coreal-er-contact-us-box .coreal-er-input-box .nice-select:hover {
  box-shadow: none;
}
.coreal-er-contact-us-box .coreal-er-input-box .nice-select .list {
  border-radius: 0;
  width: 100%;
}

.coreal-er-contact-info-box {
  border-radius: 15px;
  padding: 40px;
  background: #fff;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .coreal-er-contact-info-box {
    padding: 40px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coreal-er-contact-info-box {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .coreal-er-contact-info-box {
    margin-bottom: 30px;
    padding: 40px 20px;
  }
}
.coreal-er-contact-info-box .coreal-er-title {
  font-size: 20px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dadada;
  margin-bottom: 32px;
}
.coreal-er-contact-info-box ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.coreal-er-contact-info-box ul li {
  color: #111111;
  margin-bottom: 14px;
}
.coreal-er-contact-info-box ul li span {
  color: #777777;
  padding-left: 5px;
}
.coreal-er-contact-info-box > a {
  border-radius: 0;
  background: #f8f8f8;
  color: #777777;
  border: 0;
  line-height: 60px;
  padding: 0 45px;
  margin-top: 20px;
  display: inline-block;
  border-radius: 14px;
}
.coreal-er-contact-info-box > a.active,
.coreal-er-contact-info-box > a:hover {
  background: #3257a8;
  color: #fff;
}

/*# sourceMappingURL=style.css.map */
